<template>
    <page-loader v-if="pageLoader"/>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <hero-section :todayTiming="todayTiming" :currentTime="currentTime" :openTime="openTime" :currentDay="currentDay" :resturant="vendorData" :closeTime="closeTime" :workingDays="workingDays" @click="drawer=false"/>
     <div @click="bodyClick" :class="{'marginTop':!showProductScreen}">
       <v-row class="category-bar" v-if="showProductScreen">
          <div class="sm-screen-searchBar" :style="dynmicBgColor">
                    <div class="search-box">
                        <v-icon class="closeSearchIcon" :style="dynmicFontColor" @click.stop="searchTerm = ''">mdi-close</v-icon>
                        <input type="text" :class="{'search-border':!searchExtend}"  :placeholder="searchExtend ? 'Search' :''" v-model="searchTerm">
                        <span class="search-icon">
                          <v-icon class="custom-icon" :style="dynmicFontColor">mdi-magnify</v-icon>
                        </span>
                    </div>
                    <!-- menu -->
                    <v-menu
                        transition="slide-x-transition"
                        >
                        <template v-slot:activator="{ props }">
                            <v-app-bar-nav-icon v-bind="props" class="toggle-icon"></v-app-bar-nav-icon>
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(item, i) in allCategory"
                            :key="i"
                            >
                            <v-list-item-title  v-if="item.frontend_status == 1 && item.status == 1 " :style="{'color' : i === activeCat ? useVendorStore.resturant.main_color : '#000'}" @click="handleSingMenu(item,i)" style="cursor:pointer">{{ item.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                     </v-menu>
                    <!--end menu -->
            </div>
           <v-col cols="12" md="9">
               <div >
                   <category-scroller :activeCat="activeCat" :allCategory="allCategory" @category-menu-selected="handleSingMenu"/>
               </div>
           </v-col>
           <v-col cols="3" style="" class="lg-screen-searchBar">
                <div class="search-box lg-screen-searchBar" :class="{'extendSearchBox':searchExtend}" @click.stop="searchExtend = true">
                    <input type="text"  placeholder="Search" v-model="searchTerm">
                    <span class="search-icon">
                      <v-icon class="custom-icon">mdi-magnify</v-icon>
                    </span>
                </div>   
            </v-col>
       </v-row>
       <v-container>
            <v-row>
                <v-col cols="12" sm="12" md="8">
                   <div v-for="(category ,index) in filtersearch" class="lgScreenItems">
                       <h3 :id="'cat'+category.id" class="category-name" :style="dynmicBgColor">{{ category.name }}</h3>
                        <v-card
                        v-for="(menu,n) in category.single_menu" 
                        :loading="productCardLoad" 
                        @click="selectProduct(menu ,n)"
                        class="product-card"
                        :style="{
                            'background-color': isHoverIndex === menu.menu.name+n ? useVendorStore.resturant.main_color : '#fff',
                            'color': isHoverIndex === menu.menu.name+n ? useVendorStore.resturant.font_color : '#000'
                        }"
                        @mouseover="isHoverIndex = menu.menu.name+n"
                        @mouseleave="isHoverIndex = null"
                        >
                        <v-row>
                           <v-col cols="10" sm="10" class="menue-card-left">
                               <div class="item-img">
                                    <img :src="menu.menu.image" alt="" srcset="">
                               </div>
                               <div>
                                   <v-card-title class="product-name">{{ menu.menu.name }}</v-card-title> 
                                   <v-card-text>
                                      <p class="product-description">{{ menu.menu.description }}</p>
                                      <p v-if="menu.menu.dining_price">Price: {{ formattedPrice(menu.menu.dining_price) }}</p>  
                                      <span v-else style="color:red;font-weight:600">Customizable</span>      
                                  </v-card-text>
                                  
                               </div> 
                           </v-col>
                           <v-col cols="2"  sm="2" class="menue-card-right">               
                               <v-btn>Add</v-btn>
                           </v-col>
                        </v-row>
                        </v-card>
                   </div>
                   <div class="smScreenItems" id="cat0"> 
                    <h3 v-if="allCategory.length > 0" class="category-name" :style="dynmicBgColor">
                           {{allCategory[activeCat].name}}
                    </h3>
                        <v-card
                        v-for="(menu,n) in mbfilterSearch" 
                        :loading="productCardLoad" 
                        @click="selectProduct(menu ,n)"
                        class="product-card" 
                        v-if="!searchLoad"
                        :style="{
                            'background-color': isHoverIndex === menu.menu.name+n ? useVendorStore.resturant.main_color : '#fff',
                            'color': isHoverIndex === menu.menu.name+n ? useVendorStore.resturant.font_color : '#000'
                        }"
                        @mouseover="isHoverIndex = menu.menu.name+n"
                        @mouseleave="isHoverIndex = null"
                        >
                        <v-row>
                           <v-col cols="10" sm="10" class="menue-card-left">
                               <div class="item-img">
                                    <img :src="menu.menu.image" alt="" srcset="">
                               </div>
                               <div>
                                   <v-card-title class="product-name">{{ menu.menu.name }}</v-card-title> 
                                   <v-card-text>
                                      <p class="product-description">{{ menu.menu.description }}</p>
                                      <p v-if="menu.menu.dining_price">Price: {{ formattedPrice(menu.menu.dining_price) }}</p> 
                                      <span v-else style="color:red;font-weight:600">Customizable</span>     
                                  </v-card-text>
                                  
                               </div> 
                           </v-col>
                           <v-col cols="2"  sm="2" class="menue-card-right">               
                               <v-btn>Add</v-btn>
                           </v-col>
                        </v-row>
                        </v-card>
                        <section-loader :isLoading=searchLoad v-else />
                   </div>
                
                </v-col>
                <v-col cols="12" sm="12" md="4">
                   <div class="posCardComp">
                       <pos-cart-component 
                       :posCartData="posCartData" 
                       :vendorData="vendorData" 
                       :checkVendor="checkVendor" 
                       :reload="deliveryCondition" 
                       ref="posComponent"  
                       @change-product-section="handleChangeMainSection" 
                       :showProductScreen ="showProductScreen" 
                       @place-order-action="handlePlaceOrderAction" 
                       :placeOrderLoading ="placeOrderLoading"
                       :formattedPrice="formattedPrice"
                       />
                   </div>
                </v-col>
            </v-row> 
        </v-container>
        <div class="spceing-box"></div>
   </div>
     <v-dialog v-model="addToCartBox" max-width="650px">
       <selected-product-dilog 
       :dynmicBgColor="dynmicBgColor" 
       :singItem="singItem" 
       @close="addToCartBox=false" 
       @add-to-cart-action="handleCartData" 
       :vendorData="useVendorStore" 
       :formattedPrice="formattedPrice"
       />
   </v-dialog>
   <web-footer/>
   <div class="sm-footer">
       <small-screen-footer 
       @open-pos-dilog-box="posDilogBox=true" 
       :totalItems="posCartData.length"
       />
       <v-dialog
         v-model="posDilogBox"
         fullscreen
         :scrim="false"
         transition="dialog-bottom-transition"
       >
       <v-card>
           <v-toolbar dark :color="useVendorStore.resturant.main_color" height="40">
               <v-btn icon dark @click="posDilogBox = false">
               <v-icon style="color: #fff;">mdi-close</v-icon>
               </v-btn>
               <!-- <v-toolbar-title>Dialog Title</v-toolbar-title> -->
               <v-spacer></v-spacer>
                <v-toolbar-items style="margin:10px 20px;color: #fff ;">
                    <v-icon >mdi-cart</v-icon>
                    Cart
                </v-toolbar-items>
           </v-toolbar>
           <div class="posCardComp" style="margin: 8px 10px;">
            <pos-cart-component 
            :posCartData="posCartData" 
            :vendorData="vendorData" 
            :checkVendor="checkVendor" 
            :reload="deliveryCondition" 
            ref="posComponent"  
            @change-product-section="handleChangeMainSection" 
            :showProductScreen ="showProductScreen" 
            @place-order-action="handlePlaceOrderAction" 
            :placeOrderLoading ="placeOrderLoading"
            :formattedPrice="formattedPrice"
            />
            </div>
       </v-card>
   <!-- Dialog content -->
   <!-- ... -->
 </v-dialog>
   </div>
</template>
<script>
import NavBar from '@/layouts/default/NavBar.vue'
import CategoryComponent from '@/components/CategoryCOmponent.vue'
import PosCartComponent from '@/components/qr_code/POSCartComponent.vue'
import selectedProductDilog from '@/components/qr_code/SelectedMenueProductModal.vue' 
import heroSection from '@/layouts/default/HeroSection.vue'
import { vendorStore } from '@/store/vendor.js' 
import { posStore } from '@/store/qrCodePosStore'  
import { useUserStore } from '@/store/user.js' 
import PageLoader from '@/components/PageLoader.vue'
import smFooterComponent from '@/components/smFooterComponent.vue'
import webFooterComponent from '@/components/webFooterComponent.vue'
import sectionLoader from '@/components/sectionLoader.vue'

export default{
   components:{  
       'page-loader':PageLoader,
       'category-scroller' :CategoryComponent,  
       'selected-product-dilog':selectedProductDilog,
       'pos-cart-component' :PosCartComponent,
       'hero-section':heroSection,
       'nav-bar-vue':NavBar,
       'small-screen-footer':smFooterComponent,
       'web-footer':webFooterComponent,
       'section-loader':sectionLoader,
   },
   setup() {
        const useVendorStore = vendorStore();
        const usePosStore = posStore();    
        const userStore = useUserStore(); 
         return { useVendorStore,usePosStore,userStore};
   },
   data(){
       return{
          drawer:false,
          activeCat:0,
          menuList:[],
          productCardLoad:false,  
          selectedProduct:[],
          allCategory:[], 
          addToCartBox: false,  
          singItem:{},
          posCartData:[],
          vendorData:{},  
          currentDay:'',
          currentTime:'',
          openTime:'',
          closeTime:'',
          workingDays:[],
          todayTiming:{},
          searchTerm:"",
          pageLoader: true,
          posDilogBox:false,
          searchExtend:false,
          showProductScreen:true,
          placeOrderLoading:false,
          isHoverIndex:null,
          user_form:{
            name:null,
            phone:null,
            table_no:null,
          },
          tables:[],
          allTables:[],
          error:false,
          searchLoad:false,
       }
   },
   created() {
    this.useVendorStore.vendorType(this.$route.path);
      this.userStore.logout()   
    },
   mounted(){ 
       this.user_form.table_no = this.$route.params.table_id
       this.postItemReload();
       this.vendorLoad()
        .then(() => {
            return this.loadTableApi();
       })
   },
  computed:{
    dynmicBgColor(){
       return {
          'backgroundColor': this.useVendorStore.resturant.main_color,
          'color' : this.useVendorStore.resturant.font_color
       }
    },
    dynmicFontColor(){
       return  {'color':this.useVendorStore.resturant.main_color}
    },
    isOpen() {
       return VendorHelper.isOpen(this.currentTime,this.todayTiming)
    },
     checkVendor(){
   //    return VendorHelper.isVenderOnOrOff(this.todayTiming.status ,this.isOpen, this.vendorData.vendor_status)
        return true
     },
     filtersearch() {
           return this.allCategory.map((category) => {
           const filteredMenu = category.single_menu.filter((menu) => {
               return this.matchesSearch(menu.menu.name);
           });
           return { ...category, single_menu: filteredMenu };
           }).filter((category) => category.single_menu.length > 0);
       },
       mbfilterSearch(){
        if(this.searchTerm){
            this.searchLoad = true;
            const element = document.getElementById('cat0');
           if (element) {
              element.scrollIntoView({ behavior: 'smooth' });                            
            } 
            setTimeout(() => {
                this.searchLoad = false;
            },1000);
            const filteredMenu = this.allCategory
               .map((category) => category.single_menu.filter((menu) => this.matchesSearch(menu.menu.name))).flat();
               return filteredMenu;
        }else{
            this.searchLoad = false;
            return this.menuList.filter(item => {
               return this.matchesSearch(item.menu.name)  
           });
        }
    },
       nameValidtion(){
          return !!this.user_form.name;
       },
       phoneValidtion(){
          return !!this.user_form.phone;
       },
       tableValidtion(){
        return !!this.user_form.table_no; 
       }

 
   },
  
   methods:{ 
     formattedPrice(price){
      return VendorHelper.formatCurrency(price);
    },
     isOpenpriceSize(menueProducts){
            menueProducts.forEach((menu,ind) =>{
                menu.single_menu.forEach((singleMenue,i) =>{
                    if(singleMenue.menu.menu_size.length > 0){
                        singleMenue.menu.menu_size.forEach((item,n) =>{
                            if(item.item_size.name==="openprice"){
                                this.allCategory[ind].single_menu.splice(i, 1)
                            }
                        })
                    }
                })
            })
        },
        handlePlaceOrderAction(data){ 
            
            if(!this.nameValidtion || !this.phoneValidtion || !this.tableValidtion){
                this.handleChangeMainSection();
                this.error =true
                Notification.tableErr('Name and Phone number Required');
                this.posDilogBox = false;
            }else{

                this.placeOrderLoading=true;
                const obj ={
                    date : data.date,
                    time : data.time,
                    amount:data.totalPay,
                    tax : data.taxRate,
                    sub_total : data.ItemtotaPrice,
                    item : JSON.stringify(data.itemsOrder), 
                    vendor_id:this.vendorData.id,
                    delivery_type:'DINING',
                    payment_type:'INCOMPLETE ORDER',
                    table_no:this.user_form.table_no,
                    order_status:'APPROVE',
                    payment_status:0,
                    delivery_charge:0,
                    notes:data.orderNote,
                    user_name:this.user_form.name,
                    mobile:this.user_form.phone,
    
                }
                this.$axios
                     .post(this.useVendorStore.base_url+'apiBookOrderVuejsQR3',obj)
                     .then(response =>{
                        this.loadTableApi();
                        Notification.successPlaceOrder();
                        this.$router.push({name:'AfterQrOrderSuccess',params: {tb_id: this.$route.params.table_id},})
                     })
                     .catch(error =>{
                        console.log(error) 
                       if(error){
                           this.placeOrderLoading=false;
                           if(!error.response.data.success){
                              Notification.tableErr(error.response.data.msg)
                           }
                       }
                     })
            }
            
       },
       handleChangeMainSection(){
          this.showProductScreen = false;
          this.posDilogBox = false;
          this.$router.push('/qr-code/checkout/'+this.$route.params.table_id)
          const element = document.getElementById('userDetails');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });               
            }
       },
  
       bodyClick(){
           this.searchExtend =false;
           this.drawer =false;
           // this.searchTerm =null;
       },
       matchesSearch(text) {
           const searchTerm = this.searchTerm.toLowerCase();
           return text.toLowerCase().includes(searchTerm);
       },
       handleCartData(data){
           const addons = this.getSelectedAddon(data);
           const sizes  = this.getSelectedSizesAddon(data);
           this.posCartData.push({
               selectdItems:data.items,
               selectedItemssizes:data.sizes_addons,
               selectedItemsAdon:data.addons,
               SelectedSizes:sizes
           });
           this.usePosStore.PosItemStore(this.posCartData);
           this.addToCartBox = false;  
           this.postItemReload();  
       },
       getSelectedAddon(data){
           var addons = [];
           data.items.addon.forEach(group =>{
               group.addon.forEach(item =>{
                   if(item.addon.is_checked){
                       addons.push(item)
                   }
               })
           })
           return addons;
       },
       getSelectedSizesAddon(data){
           var obj={};
           data.items.sizes.forEach(size =>{
                if(size.selected){
                   obj.id=size.id;
                   obj.display_discount_price=size.display_discount_price;
                   obj.display_price=size.display_price;
                   obj.item_size_id=size.item_size_id;
                   obj.menu_id=size.menu_id;
                   obj.price=size.price;
                   obj.size_dining_price=size.size_dining_price;
                   obj.vendor_id=size.vendor_id;
                   obj.addons=[]
                   size.menu_addon.forEach(item =>{
                       if(item.addon.is_checked){
                           obj.addons.push(item);
                       }
                   })
                }
           });
           return obj
       },
       isSelect(data){
          return this.posCartData.some(item => item.selectdItems.id === data.items.id);
       },
       isSelectSize(data){
           console.log(data.items.sizes.length)
       },
       postItemReload(){
           this.posCartData = this.usePosStore.posItems;
       },
       vendorLoad(){
           return new Promise((resolve, reject) => {
               this.$axios
               .get(this.useVendorStore.base_url+'single_vendor/'+this.useVendorStore.vender_id)
               .then(({ data }) => { 
                     this.pageLoader=false; 
                     this.useVendorStore.vednorStorage(data);  
                     this.allCategory = data.data.MenuCategory;
                     this.isOpenpriceSize(data.data.MenuCategory);
                     this.menuList = data.data.MenuCategory[0].single_menu
                     this.vendorData = data.data.vendor;    
                     this.workingDays = data.data.WorkDays;
                     const dayTime = VendorHelper.setTimeZone(data.data.vendor.timezone); 
                     this.currentDay = dayTime[0];
                     this.currentTime = dayTime[1];
                     const today = data.data.WorkDays.find(day => day.day_index === this.currentDay);
                     this.todayTiming = today;
                     this.openTime = today.period_list[0].start_time
                     this.closeTime = today.period_list[0].end_time

                     resolve();
                     
             }) .catch(error => {
                 reject(error); // Reject the promise if there's an error
               });
           })
       },
       loadTableApi(){
        return new Promise((resolve, reject) => {
            this.$axios
               .get(this.useVendorStore.base_url+'apiTableSettingVue/'+this.useVendorStore.vender_id)
               .then(({ data }) => { 
                   this.allTables = data.data.tables
                   data.data.tables.forEach(table =>{
                         this.tables.push(table.booked_table_number)
                   })
                   resolve();
               }).catch(error => {
                   reject(error); // Reject the promise if there's an error
              });
        })
       },

       handleSingMenu(category,index){
           this.activeCat = index;
           this.menuList = category.single_menu;
           const element = document.getElementById('cat'+category.id);
           const element2 = document.getElementById('cat0');
          if (element) {
             element.scrollIntoView({ behavior: 'smooth' }); 
             element2.scrollIntoView({ behavior: 'smooth' });               
           } 
       },
       getCategoryByItem(item) {
            return this.allCategory.find(category =>
                category.single_menu.some(menu =>
                    item.menu.id === menu.menu.id
                )
            );
        },
       selectProduct(data,index){
        this.getCategoryByItem(data)
          var categoryData = this.getCategoryByItem(data);
          
          this.addToCartBox =true;
          const sizes = [];
          data.menu.menu_size.forEach(item=>{    
            if(item.item_size.name === "grams")  {  
                var isSelect = true;
            } else{
                var isSelect = false;
            }   
             sizes.push({
                 id:item.id,
                 display_discount_price:item.display_discount_price,     
                 display_price:item.display_price,
                 group_menu_addon:item.group_menu_addon,
                 item_size:item.item_size,
                 item_size_id:item.item_size_id, 
                 menu_addon:item.menu_addon,
                 menu_id:item.menu_id,
                 price:item.price,
                 size_dining_price:item.size_dining_price,
                 vendor_id:item.vendor_id,
                 selected:isSelect,
             });
          });
              const adon_group=[];
              data.menu.group_menu_addon.forEach(group=>{
                const exists = adon_group.some((item) => item.addon_cat_id === group.addon_category_id);
                if (!exists) {
                adon_group.push({
                        addon_cat_id:group.addon_category_id,
                        adon_group:group,
                        addon:[]
                    });
                }
              });  
               adon_group.forEach(cat => {
                  data.menu.menu_addon.forEach(product => {
                    
                    if(cat.addon_cat_id === product.addon_category_id){  
                       product.addon.is_checked = false;
                       product.addon.isSelect = false;
                       product.qty = 1;
                       cat.addon.push(product);
                       
                    }
                 })
              });
          this.singItem = {
                 cat_name:categoryData.name,
                 cat_id:categoryData.id,
                //  id:data.menu.id,
                 id:data.id,
                 name : data.menu.name,
                 price:data.menu.price,
                 dining_price:data.menu.dining_price,
                 img:data.menu.image,
                 description:data.menu.description,
                 qty:1,
                 grameSize:1,
                 addon:adon_group,
                 sizes:sizes,
                 
          }
       },
       isSelected(product) {
         return this.selectedProduct.some((item) => item.id === product.menu.id);   
      },
      itemRemove(product) {
       
       const index = this.selectedProduct.findIndex( 
           (item) => item.id === product.menu.id
       );
       if( this.selectedProduct.splice(index, 1)){
           alert("yes")
       }else{
           alert("no")
       }
   },
   }
 
}


</script>



<style scoped>
.category-bar {
 position: sticky;
 top: 0;
 z-index: 100;
 background-color: #ffffff;  
 padding: 0 !important;
 box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
 margin: 0;
 border-radius: 0 !important;
}
.menue-card-left{
   display: flex;
   /* justify-content: center; */
   align-items: center;
   padding:  20px;

}
.menue-card-left img{
   width: 75px;
   height: 75px;
   border-radius: 100%;
}
.menue-card-right{
   display: flex;
   justify-content: center;
   align-items: center;
}
.category-name{
   padding: 20px ;
   box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.product-card{
   margin-bottom: 10px;
}

.v-card-title{
   font-size:16px !important;   
} 
.custom-text-field  {
  color: #d7171c !important; /* Replace with your desired custom color */
  width:100%
}

.posCardComp{
   position: sticky;
   top: -10px;
}
.search-box {
 position: relative;
 display: inline-block;
 width: 100%;
}

.search-box input[type="text"] {
  height: 40px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 60px;
  width: 100%;
  background-color: #fff;

 
}

.search-icon {
 position: absolute;
 top: 0;
 right: 0;
 width: 30px;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 pointer-events: none;
}

.search-icon i {
 font-size: 20px;
}
.sm-footer{
   display: none;
}
.spceing-box{
   display: none;
}
.lg-screen-searchBar{
    display: flex;
    align-items:center;
}

@media(max-width:960px){
    .closeSearchIcon{
        position: absolute;
    top: 4px;
    left: 5px;
    cursor: pointer;
    }
    .search-box input[type="text"]{
        padding: 15px 30px !important;
    }
   .extendSearchBox{
         color: #d7171c;
         position: absolute;
         right: 10px;
         width: 50%;
         background-color: #fff;
    }
    .lg-screen-searchBar{
           display: none;
     }
     .extendSearchBox{
          color: #d7171c;
          position: absolute;
          right: 10px;
          width: 93%;
     }
   
     .sm-screen-searchBar{
        display: flex !important;
        align-items: center;
        width: 100%;
        padding-left: 10px;
     }
     .sm-screen-searchBar .search-box input{
        height: 30px !important;

     }
    
   .sm-footer{
      display: block !important;
   }
   .spceing-box{
       display: block;
       height: 100px;
   }
}
.marginTop{
    margin-top:90px !important
}
.MainCard{
    padding: 30px 50px;
}
.sm-screen-searchBar{
    display: none;
}
.smScreenItems{
    display: none;
}
@media(max-width:768px){
   .lgScreenItems{
    display: none !important;
   } 
   .smScreenItems{
    display: block !important;
    padding-top:20px;
   }
}
@media(max-width:599px){
    /* .menue-card-left{
        display: grid !important;
        text-align: center;   
    } */
    .menue-card-left{
        padding: 0 !important;
    }
    .item-img{
        display: flex;
        justify-content: center;
    }
    
    .menue-card-right .v-btn{
        position:absolute;
        bottom: 5px;
    }
    .product-card{
        padding: 20px;
    }
    .menue-card-left .product-name,.menue-card-left .product-description{
        width: 290px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    .menue-card-left img{
        width: 70px !important;
        height: 70px !important;
        border-radius: 6% !important;
    }
    .menue-card-right .v-btn{
    bottom: 0 !important;
    }
}
@media (max-width: 375px) {
    .menue-card-left .product-name,
    .menue-card-left .product-description {
      width: 240px !important;
    }
  }
</style>