<template>
  <v-app-bar app style="margin-bottom: 40px;" :class="{'bg-transprant':isMainPage,'box-shodow':!isMainPage}">
    <v-container>
        <v-app-bar-nav-icon v-if="!drawer" @click="$emit('close-drawer')" :style="themColorWithFont" class="toggle-icon"></v-app-bar-nav-icon>
        <v-app-bar-nav-icon class="toggle-icon" :style="themColorWithFont" v-else @click="$emit('close-drawer')">
          <v-icon>mdi-close</v-icon>
        </v-app-bar-nav-icon>
        <v-toolbar-title>
            <router-link :to="homeRoutesUrl">
              <img :src="useVendorStore.resturant.image" alt="" style="cursor: pointer;">
            </router-link>
          </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-container>
      </v-app-bar>
    <div :class="['custom-drawer', { 'drawer-open': drawer }]">
      <div>
        <div class="main-menu-nav" :style="themColorWithFont"><h3>Main Menu</h3></div>
         <div  v-if="!checkLoggin"  v-for="item in publickSidebar" :key="item.title">
           <router-link v-if="item.status == 1" :to="item.route" class="link-list-sm">
               <v-icon :style="themColor" style="margin-right:5px">{{item.icon }}</v-icon>
               <v-list-item-title>{{ item.title }}</v-list-item-title>
           </router-link>
         </div>
         <div v-else v-for="item in sidebarItems">
           <router-link :to="item.route" class="link-list-sm" v-if="item.status == 1">
               <v-icon :style="themColor" style="margin-right:5px">{{ item.icon }}</v-icon>
               <v-list-item-title>{{ item.title }}</v-list-item-title>
           </router-link>
         </div>
        <router-link v-if="!checkLoggin && homeRoutesUrl === '/'" to="/login" class="link-list-sm">
          <v-icon :style="themColor" style="margin-right:5px">mdi-account-circle</v-icon>
          <v-list-item-title>Login</v-list-item-title>
        </router-link>
        <router-link v-if="!checkLoggin && homeRoutesUrl === '/'" to="/register" class="link-list-sm">
          <v-icon :style="themColor" style="margin-right:5px">mdi-account-plus</v-icon>
          <v-list-item-title>Signup</v-list-item-title>
        </router-link>
        <router-link v-if="homeRoutesUrl === '/' && driverPageStatus ==1" to="/driver-signup" class="link-list-sm">
          <v-icon :style="themColor" style="margin-right:5px">mdi-car</v-icon>
          <v-list-item-title>Driver Signup</v-list-item-title>
        </router-link>

      </div>
    </div>
</template>

<script >
import User from '@/helper/User';
import { vendorStore } from '@/store/vendor.js'
import helper from '@/helper/helper';

  export default {
    props:['drawer'],
    setup() {
        const useVendorStore = vendorStore(); 
         return { useVendorStore};
    },
    data(){
      return{
        location:'light',
        locations:['dark','light'],
        role:localStorage.getItem('role'),
        // role:true,
        items: [
        { title: 'Profile' ,routeName:""},
        { title: 'Logout' ,routeName:"logout"},
        
      ],
      homeRoutesUrl:'',
   
      publickSidebar:[
        {title:'Home',icon: 'mdi-home', route: this.useVendorStore.homePageURL,status:1},
        {title:'About Us',icon: 'mdi-information', route: '/about-us'},
        {title:'Terms & Condition',icon: 'mdi-home', route: '/terms-condition'},
        {title:'Returns & Refunds',icon: 'mdi-currency-usd', route: '/return-refunds'},
        {title:'Company Details',icon: 'mdi-domain', route: '/company-details'},
        {title:'Privacy Policy',icon: 'mdi-shield-check-outline', route: '/privacy'},
        {title:'Feedback',icon: 'mdi-comment-outline', route: '/feedback',status:1},
        
      ],
      sidebarItems: [
        {title:'Home',icon: 'mdi-home', route: '/',status:1},
        { title: 'Profile', icon: 'mdi mdi-account', route: '/profile', status:1 },
        {title:'About Us',icon: 'mdi-information', route: '/about-us'},
        { title: 'My Orders', icon: 'mdi-shopping', route: '/orders' ,status:1},
        {title:'Terms & Condition',icon: 'mdi-home', route: '/terms-condition'},
        {title:'Returns & Refunds',icon: 'mdi-currency-usd', route: '/return-refunds'},
        {title:'Company Details',icon: 'mdi-domain', route: '/company-details'},
        {title:'Privacy Policy',icon: 'mdi-shield-check-outline', route: '/privacy'},
        {title:'Feedback',icon: 'mdi-comment-outline', route: '/feedback',status:1},
        { title: 'LogOut', icon: 'mdi-logout', route: '/logout',status:1 },
      ],
      driverPageStatus:'',
      bgColor:"",
      fontColor:"",
      
      }
    },
    
    computed:{
      isMainPage(){
        if(this.$route.name === 'resturant' || this.$route.name === 'qrCode'){
          return true
        }else{
          return false
        }
      },
      checkLoggin(){  
       return User.loggedIn();
      },
      homeRoutesUrl(){
        return this.useVendorStore.homePageURL;
        },
      themColorWithFont(){
        return {
          'background-color':this.useVendorStore.resturant.main_color,
          'color':this.useVendorStore.resturant.font_color
        }
      },
      themColor(){
        return {
          'color':this.useVendorStore.resturant.main_color
        }
      }
    },
    created(){
      if(VendorHelper.getAppVersionFromStorage() != this.useVendorStore.version){
        VendorHelper.removeCatche();
        VendorHelper.storeAppVersion();
        window.location.reload(true);
        this.$router.push('/');
      }
    },
    mounted(){
      this.afterLoad();
    },
    methods:{
      
      afterLoad(){
        this.$axios
          .get(
            this.useVendorStore.base_url +
              "vendorPageStatusVueJs/" +
              this.useVendorStore.vender_id
          )
          .then(({ data }) => {
            this.publickSidebar[1].status = data.status.aboutUs_page_status;
            this.publickSidebar[2].status = data.status.terms_page_status;
            this.publickSidebar[3].status = data.status.refunds_status;
            this.publickSidebar[4].status = data.status.companyDetails_status;
            this.publickSidebar[5].status = data.status.privacy_page_status;
            this.sidebarItems[2].status = data.status.aboutUs_page_status;
            this.sidebarItems[4].status = data.status.terms_page_status;
            this.sidebarItems[5].status = data.status.refunds_status;
            this.sidebarItems[6].status = data.status.companyDetails_status;
            this.sidebarItems[7].status = data.status.privacy_page_status;
            this.driverPageStatus = data.status.driver_signup_status
          });
         this.bgColor = this.useVendorStore.resturant.main_color;
         this.fontColor = this.useVendorStore.resturant.font_color;
      },
      allLinks(status){
        
      },
      PushLink(routeName){
        this.$router.push({ name: routeName })
      },
    }
  }
</script>
<style scoped>
.box-shodow{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset !important;
}
.bg-transprant{
  background: transparent !important;
  color: #fff !important;
}
.main-menu-nav{
    padding: 12px;
    font-size: 23px;
    text-align: center;
}
.them-color{
  color: #259A36;
}
.border-right{
  border-right: 2px solid #259A36;
}
.drope-down-list{
  cursor: pointer;
}
.drope-down-list:hover{
  /* background-color: #259A36; */
  color: #259A36;
  /* padding: 10px; */
}
.vertical-line {
  height: 60%;
    width: 1px;
    background-color: #259A36;
    margin: 12px 0;
}
.v-toolbar-items .v-btn{
  text-transform: capitalize;
}

.custom-drawer {
  position: fixed;
  top: 0;
  left: -300px;
  width: 250px;
  height: 100vh;
  background-color: #fff;
  transition: left 0.3s ease;
  z-index: 999;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.drawer-open {
  left: 0;
}
.toggle-icon{
  position: absolute;
    right: 5%;
    top: 10px;

}
.v-toolbar{
   box-shadow: none !important;
   /* color: #ffff !important; */
}
.v-toolbar-title img{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-top: 5px;
}
.v-list-item__content{
    width: 100%;
    display: flex !important;
}
.link-list-sm{
    padding: 15px 30px;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    color: #000;
}
.footer-bottom{
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 13px;
  width: 100%;
}
.footer-bottom .v-col-4{
  display: grid !important;
  cursor: pointer;
}
.footer-bottom .v-col-4 .mdi{
  width: 100%;
}
</style>
