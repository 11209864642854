<template>
  <page-loader v-if="pageLoader" />
  <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer" />
  <div style="margin-top: 70px" @click="drawer = false">
    <v-container>
      <!-- <v-btn @click="loadFirbase">check</v-btn> -->
      
      <v-card
        v-if="groupOrders.length > 0"
        class="mx-auto mb-5 itemsCard"
        v-for="(group, index) in groupOrders"
        :key="index"
      >
        <div class="topbanner" :style="dynmicBgColor">
          <v-row>
            <v-col cols="12" sm="9" class="card-head-left">
              <div style="display: flex; align-items: center; width: 100%">
                <img style="" :src="group.vendorLogo" alt="" srcset="" />
                <div class="resturan-info">
                  <div class="name-with-phone">
                    <h6>{{ group.vendorName }}</h6>
                    <span
                      style="cursor: pointer"
                      @click="initiateCall(group.vendorContact)"
                      ><v-icon style="font-size: 15px">mdi-phone</v-icon
                      >{{ group.vendorContact }}</span
                    >
                  </div>
                  <p class="lg-screen-info" style="font-size: 14px">
                    <v-icon style="font-size: 15px">mdi-map-marker</v-icon
                    >{{ group.vendorAddress }}
                  </p>
                  <!-- <div></div> -->
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      width: 100%;
                    "
                  >
                    <span class="sm-screen-info"
                      >Order {{ group.order_id }}</span
                    >
                     <div style="font-size:12px;margin-top:5px" v-if="group.address">
                  <v-icon>mdi-map-marker</v-icon>
                  <span>{{group.address}}</span>
                </div>
                    <!-- <div class="dateTime">
                                  <v-icon>mdi-clock</v-icon>
                                  {{ group.time +' ' + group.date}}
                                </div> -->
                  </div>

                  <div class="sm-screen-info">
                    <div
                      v-if="
                        completeOrderCheck(
                          group.order_status,
                          group.timeLeft
                        ) === 'uncomplete' && group.approve_timer
                      "
                    >
                      <small> Delivery may be delayed contact the vendor</small>
                    </div>
                    <div
                      v-else-if="
                        completeOrderCheck(
                          group.order_status,
                          group.timeLeft
                        ) === 'complete'
                      "
                    >
                      <p style="font-size: 14px">Delivery has been Delivered</p>
                    </div>
                    <div
                      v-else
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="left">
                        <v-icon left>mdi-clock-outline</v-icon>Estimate Time
                      </div>
                      <div
                        class="right"
                        v-if="group.timeLeft >= 0"
                        style="font-size: 25px"
                      >
                        {{ formatTime(group.timeLeft) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              style="display: flex; align-items: center"
              class="lg-screen-info"
            >
              <div style="padding-left: 10px">
                <span class="badge-success">{{
                  statusCheck(group.order_status, group.delivery_type)
                }}</span>
                <br />
                <span class="">Order {{ group.order_id }}</span>
                <div class="dateTime" >
                  <v-icon>mdi-clock</v-icon>
                  <span v-if="group.delivery_time">{{ group.delivery_time }}</span>
                  <span v-else>{{ group.date + " " + group.time }}</span>
                  
                </div>
                <div style="font-size:12px;margin-top:5px" v-if="group.address">
                  <v-icon>mdi-map-marker</v-icon>
                  <span>{{group.address}}</span>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="sm-screen-badge">
          {{ statusCheck(group.order_status, group.delivery_type) }}
        </div>
        <!-- <v-divider></v-divider> -->
        <v-card-text class="padding25">
          <div class="sm-screen-info">
            <div
              class="order-progress-section"
              v-if="group.order_status !== 'CANCEL'"
            >
              <order-tracking-progress-bar
                :orderStatus="group.order_status"
                :deliveryType="group.delivery_type"
              />
            </div>
            <div v-else class="cancelOrder" :style="dynmicBgColor">
              {{
                "Your order has been canceled. Reason: " +
                group.cancel_reason +
                " by " +
                group.cancel_by
              }}
            </div>
          </div>
          <v-row>
            <v-col cols="12" sm="8" md="9">
              <div class="scrollerItems">
                <div v-for="(item, n) in parseOrderItems(group.order_data)" :key="n">
                  <div class="mb-4">
                    <div class="font-weight-bold">
                      <div class="d-flex" style="align-items: center">
                        <img
                          style="width: 45px; height: 45px; border-radius: 100%"
                          :style="{
                            border:
                              '2px solid ' +
                              useVendorStore.resturant.main_color,
                          }"
                          :src="item.menu[0].image"
                          alt=""
                          srcset=""
                        />
                        <div class="ml-4">
                          <p style="font-size: 15px" :style="dynmicFontColor">
                            {{ item.quantity + "X " + item.menu[0].name }}
                            <small style="opacity: 0.5" v-if="item.size"
                              >({{ item.size.size_name }})</small
                            >
                          </p>

                          <small>Price : {{ formattedPrice(item.total_amount.toFixed(2)) }}</small>
                        </div>
                      </div>
                      <div
                        class="addonsSections"
                        :style="{
                          'border-left':
                            '3px solid ' + useVendorStore.resturant.main_color,
                        }"
                        v-if="item.menu[0].addons.length > 0"
                      >
                        <div
                          class="addon-item"
                          v-for="addon in item.menu[0].addons"
                          :key="addon.id"
                        >
                          <div
                            style="width: 40px; height: 2px"
                            :style="dynmicBgColor"
                          ></div>
                          <small class="ml-3">{{
                            addon.quantity + "X " + addon.name
                          }}</small>
                          <small>({{ formattedPrice(addon.price) }})</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg-screen-info">
                <div
                  class="order-progress-section"
                  v-if="group.order_status !== 'CANCEL'"
                >
                  <order-tracking-progress-bar
                    :orderStatus="group.order_status"
                    :deliveryType="group.delivery_type"
                  />
                </div>
                <div v-else class="lgScreen-cancelMsg" :style="dynmicFontColor">
                  {{
                    "Your Order has been Cancel Reason: " +
                    group.cancel_reason +
                    " by " +
                    group.cancel_by
                  }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <!-- <v-btn @click="ReloadAfterStatusChange">startTimers</v-btn> -->
              <!-- timer -->
              <div class="calculationArea">
                <div
                  class="culculation lg-screen-info"
                  style="padding: 3px"
                  v-if="
                    completeOrderCheck(group.order_status, group.timeLeft) ===
                      'uncomplete' && group.approve_timer
                  "
                >
                  <v-alert
                    :color="useVendorStore.resturant.main_color"
                    theme="dark"
                    border
                  >
                    Delivery may be delayed contact the vendor
                  </v-alert>
                </div>
                <div
                  class="culculation lg-screen-info"
                  style="padding: 3px"
                  v-else-if="
                    completeOrderCheck(group.order_status, group.timeLeft) ===
                    'complete'
                  "
                >
                  <v-alert
                    type="success"
                    :color="useVendorStore.resturant.main_color"
                    :style="{ color: useVendorStore.resturant.font_color }"
                    border
                  >
                    <v-text v-if="group.delivery_type === 'DELIVERY'"
                      >Delivery has been Delivered</v-text
                    >
                    <v-text v-else>Your order has been picked up</v-text>
                  </v-alert>
                </div>
                <div v-else>
                  <div
                    v-if="group.order_status !== 'CANCEL'"
                    class="culculation timerArea lg-screen-info"
                    :style="dynmicBgColor"
                  >
                    <div class="left">
                      <v-icon left>mdi-clock-outline</v-icon>Estimate Time
                    </div>
                    <div
                      class="right"
                      v-if="group.timeLeft >= 0"
                      style="font-size: 25px"
                    >
                      {{ formatTime(group.timeLeft) }}
                    </div>
                  </div>
                  <div v-else>
                    <v-alert
                      :color="useVendorStore.resturant.main_color"
                      theme="dark"
                      border
                    >
                      Delivery has been Canceled contact the vendor
                    </v-alert>
                  </div>
                </div>
                <v-divider class="lg-screen-info"></v-divider>
                <div class="culculation">
                  <div class="left">Delivery Type</div>
                  <div class="right">{{ group.delivery_type }}</div>
                </div>
                <v-divider></v-divider>
                <div class="culculation">
                  <div class="left">Sub Total</div>
                  <div class="right">{{ formattedPrice(group.sub_total) }}</div>
                </div>
                <v-divider></v-divider>
                <div class="culculation">
                  <div class="left">Total Tax</div>
                  <div class="right">{{ formattedPrice(group.tax) }}</div>
                </div>
                <v-divider></v-divider>
                <div class="culculation" v-if="group.delivery_charge > 0">
                  <div class="left">Delivery Charge</div>
                  <div class="right">{{ formattedPrice(group.delivery_charge) }}</div>
                </div>
                <v-divider v-if="group.discounts !== null || group.discounts > 0"></v-divider>
                <div class="culculation" v-if="group.discounts !== null || group.discounts > 0">
                  <div class="left">Discounted Price</div>
                  <div class="right">{{ formattedPrice(group.discounts) }}</div>
                </div>
                <v-divider v-if="group.discounts !== null || group.discounts > 0"></v-divider>
                <v-divider v-if="group.delivery_charge > 0"></v-divider>
                <div v-if="group.payment_type === 'STRIPE'" class="culculation">
                  <div class="left">Card Surcharge</div>
                  <div class="right">{{ formattedPrice(group.card_charges) }}</div>
                </div>
                <v-divider v-if="group.payment_type === 'STRIPE'"></v-divider>
                <div class="culculation" :style="dynmicFontColor">
                  <div class="left">Total Amount</div>
                  <div class="right">{{ formattedPrice(group.amount) }}</div>
                </div>
                <!-- <div v-if="firebaseStatus[group.id]">
                                         <div class="take_away_btn" v-if="firebaseStatus[group.id].status === 'PICKUP'">
                                           <v-divider></v-divider>
                                             <v-btn class="button" :loading="trackingBtnLoading" @click="OrderTracking(group.id)"><v-icon>mdi-map-marker-check</v-icon>Order Track</v-btn>
                                         </div> 
                                     </div> -->
              </div>
            </v-col>
            <!-- :to='"/order-tracking/"+group.id' -->
          </v-row>
        </v-card-text>
        <!-- <div style="display: flex;justify-content:end;padding:30px">
                           <v-icon class="down-icon-card">mdi-arrow-down</v-icon>
                       </div> -->
        <div
          class="card-footer"
          v-if="group.order_status === 'PICKUP'"
          :style="dynmicBgColor"
        >
          <div class="left">
            <img :src="group.deliveryPersonImage" alt="" srcset="" />
            <div>
              <h3>{{ group.deliveryPersonName }}</h3>
              <span
                style="cursor: pointer"
                @click="initiateCall(group.deliveryPersonContact)"
                ><v-icon style="font-size: 15px">mdi-phone</v-icon
                >{{ " " + group.deliveryPersonContact }}</span
              >
            </div>
          </div>
          <div class="right">
            <v-btn
              class="default-btn"
              @click="initiateCall(group.deliveryPersonContact)"
              >Call</v-btn
            >
            <v-btn
              class="dark-btn"
              :loading="trackingBtnLoading"
              @click="OrderTracking(group.id)"
              >Track Order</v-btn
            >
          </div>
        </div>
      </v-card>

      <v-card v-else>
        <v-alert :color="useVendorStore.resturant.main_color" type="info"
          >Order not found</v-alert
        >
      </v-card>
      <div class="spceing-box"></div>
    </v-container>
    <web-footer />
    <div class="sm-footer">
      <small-screen-footer
        @open-pos-dilog-box="$router.push('/')"
        :totalItems="0"
      />
    </div>
  </div>
</template>

<script>
import NavBar from "@/layouts/default/NavBar.vue";
import { posStore } from "@/store/posStore";
import { vendorStore } from "@/store/vendor.js";
import { useUserStore } from "@/store/user.js";
import PageLoader from "@/components/PageLoader.vue";
import smFooterComponent from "@/components/smFooterComponent.vue";
import firebase from "@/helper/firebaseInit.js";
import orderTrackingProgressBar from "@/components/orderTrackingProgressBarComponent.vue";
import webFooterComponent from "@/components/webFooterComponent.vue";
import snackBar from "@/components/snackbarComponent.vue";
export default {
  components: {
    "nav-bar-vue": NavBar,
    "page-loader": PageLoader,
    "small-screen-footer": smFooterComponent,
    "order-tracking-progress-bar": orderTrackingProgressBar,
    "snack-bar": snackBar,
    "web-footer": webFooterComponent,
  },
  setup() {
    const userStore = useUserStore();
    const useVendorStore = vendorStore();
    const usePosStore = posStore();
    return { userStore, useVendorStore, usePosStore };
  }, 
  created() {
    if (!User.loggedIn()) {
      this.$router.push("/");
    }
  },
  data() { 
    return {
      drawer: false,
      groupOrders: [],
      trackingBtnLoading: false,
      pageLoader: true,
      firebaseStatus: [],
      orderStatus: "",
      intervalIds: [],
      changeStatusAudio: "audio/pop.mp3",
      isFirstLoad:true,
    };
  },
  created() {
    if (!User.loggedIn()) {
      this.$router.push("/");
    }
  },

  mounted() {
    VendorHelper.updatePageTitle(
      this.useVendorStore.resturant.name + "- Ozpos"
    );
    this.firstLoadFirbaseData().then(() => {
      this.afterReload();
    });
    
  },
  computed: {
    database() {
      return firebase.app().database();
    },
    formatTime() {
      return (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${this.padNumber(minutes)}:${this.padNumber(seconds)}`;
      };
    },
    dynmicFontColor() {
      return {
        color: this.useVendorStore.resturant.main_color,
      };
    },
    dynmicBgColor() {
      return {
        color: this.useVendorStore.resturant.font_color,
        backgroundColor: this.useVendorStore.resturant.main_color,
      };
    },
     currentDateTime(){
        return new Date(VendorHelper.setTimeZone(this.useVendorStore.resturant.timezone));
        // return new Date(VendorHelper.setTimeZone(this.vendorData.timezone));
    }
  },
  methods: {
    formattedPrice(price){
        return VendorHelper.formatCurrency(price);
    },
    initiateCall(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    truncatedString(srting, leng) {
      if (srting) {
        if (srting.length > leng) {
          return srting.slice(0, leng) + "...";
        } else {
          return srting;
        }
      }
    },
    completeOrderCheck(status, timeLeft) {
      if (status === "PENDING") {
        return "start";
      } else if (status === "DELIVERED" || status === "COMPLETE") {
        return "complete";
      } else {
        const totalSeconds = 30 * 60;
        if (timeLeft <= 1) {
          return "uncomplete";
        } else {
          return "start";
        }
      }
    },
    statusCheck(status, type) {
      if (status === "READY TO PICKUP") {
        if (type === "TAKEAWAY") {
          return "WAITING FOR USER TO PICKUP";
        } else {
          return "AWAITING DRIVER PICKUP";
        }
      } else if (status === "ACCEPT") {
        return "AWAITING DRIVER PICKUP";
      } else if (status === "PICKUP") {
        return "DRIVER PICKED UP THE FOOD";
      } else {
        return status;
      }
    },
    firstLoadFirbaseData() {
      return new Promise((resolve, reject) => {
        this.loadFirebase(this.userStore.user.id);
        //    const dataRef = firebase.database().ref(`/orders/284`);
        const dataRef = firebase
          .database()
          .ref(`/userOrder/${this.userStore.user.id}`);
        dataRef.on("value", (snapshot) => {
         
          // console.log('firbase data');
          // console.log(snapshot.val());
          this.firebaseStatus = snapshot.val();
          // this.completeOrderCheck()
          let arr = snapshot.val();
          if(!this.isFirstLoad){
            this.ReloadAfterStatusChange();
          }
          this.isFirstLoad = false;
        
          //    for (const key in arr) {
          //        if(arr[key].status === 'APPROVE' || arr[key].status === 'PICKUP'){
          //            this.ReloadAfterStatusChange();
          //            break;
          //     //    }else if (arr[key].status === 'PICKUP') {
          //     //        this.ReloadAfterStatusChange();
          //     //        break;
          //        }
          //    }

          this.groupOrders.forEach((order, index) => {
            this.completeOrderCheck(order.order_status, order.timeLeft);
          });
          resolve();
        });
      });
    },

    loadFirebase(userId) {
      return new Promise((resolve, reject) => {
        const dataRef = this.database.ref(`/orders/${userId}`);
        dataRef.on(
          "value",
          (snapshot) => {
            const data = snapshot.val();
            resolve(data);
          },
          (error) => {
            reject(error); // Reject the Promise if there's an error
          }
        );
      });
    },

    OrderTracking(id) {
      this.trackingBtnLoading = true;
      const obj = {
        vendor_id: this.useVendorStore.vender_id,
        order_id: id,
      };
      const base_url = this.useVendorStore.base_url;
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      this.$axios
        .post(base_url + "liveTrackVuejsData", obj, { headers })
        .then((response) => {
          console.log(response);
          this.usePosStore.liveTrackingData(response.data.data);
          this.$router.push({
            name: "orderTracking",
            params: {
              lat: response.data.data.User_lat,
              lang: response.data.data.User_lang,
              driverId: response.data.data.Driver_id,
            },
          });
          this.trackingBtnLoading = false;
        })
        .catch((error) => {
          this.trackingBtnLoading = false;
          if (error.response.status === 401) {
            this.userStore.logout();
            this.$router.push("/");
          }
        });
    },
    ReloadAfterStatusChange() {
      console.log("load show order api")
      const base_url = this.useVendorStore.base_url;
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      this.$axios
        .get(base_url + "NewapiShowOrderVuejs", { headers })
        .then((response) => {
          console.log("Sfdsdsd");
          console.log(response);
          response.data.data.forEach((result) => {
            this.groupOrders.forEach((order, index) => {
              if (result.id === order.id) {
                this.groupOrders[index].deliveryPersonContact =
                  result.deliveryPersonContact;
                this.groupOrders[index].deliveryPersonImage =
                  result.deliveryPersonImage;
                this.groupOrders[index].deliveryPersonName =
                  result.deliveryPersonName;
                this.groupOrders[index].order_status = result.order_status;
                this.groupOrders[index].cancel_reason = result.cancel_reason;
                this.groupOrders[index].cancel_by = result.cancel_by;
                this.groupOrders[index].delivery_time = result.delivery_time;
                if (!order.approve_timer) {
                  this.groupOrders[index].approve_timer = result.approve_timer;
                  this.loadTimerData(order, index);
                }
              }
            });
          });
        }).catch((err) => {
          console.log(err);
          this.userStore.logout();
          this.$router.push("/");
        });
    },
    afterReload() {
       console.log("load show orders api")
      const base_url = this.useVendorStore.base_url;
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      this.$axios
        .get(base_url + "NewapiShowOrderVuejs", { headers })
        .then((response) => {
          this.groupOrders = response.data.data;
          for (const product of this.groupOrders) {
            product.avg_delivery_time =
              this.useVendorStore.resturant.avg_delivery_time;
            product.timeLeft = 0;
          }
          this.pageLoader = false;
          this.startTimers();
        })
        .catch((err) => {
          console.log(err);
          // if (err.status === 401) {
            this.userStore.logout();
            this.$router.push("/");
          // }
        });
    },
    parseOrderItems(object) {
      const arr = JSON.parse(object);
      return arr.cart;
    },
    convertTo24HourFormat(time) {
      const [timePart, period] = time.match(/\d+:\d+:\d+|am|pm/g);
      const [hours, minutes, seconds] = timePart.split(":");
      let convertedTime = "";
      if (period.toLowerCase() === "pm" && parseInt(hours) !== 12) {
        convertedTime = `${parseInt(hours) + 12}:${minutes}:${seconds}`;
      } else if (period.toLowerCase() === "am" && parseInt(hours) === 12) {
        convertedTime = `00:${minutes}:${seconds}`;
      } else {
        convertedTime = `${hours}:${minutes}:${seconds}`;
      }
      return convertedTime;
    },
    padNumber(num) {
      return num.toString().padStart(2, "0");
    },
    add30MinutesToTime(originalTime) {
      const [hours, minutes, seconds] = originalTime.split(":").map(Number);
      const timeObj = this.currentDateTime;
      timeObj.setHours(hours);
      timeObj.setMinutes(minutes);
      timeObj.setSeconds(seconds);
      const avg_time = parseFloat(
        this.useVendorStore.resturant.avg_delivery_time
      );
      // timeObj.setMinutes(timeObj.getMinutes() + 5);
      timeObj.setMinutes(timeObj.getMinutes() + avg_time);

      // Format the new time as HH:mm:ss
      const newTime = `${this.padNumber(timeObj.getHours())}:${this.padNumber(
        timeObj.getMinutes()
      )}:${this.padNumber(timeObj.getSeconds())}`;

      return newTime;
    },
    //   timers code

    startTimers() {
      this.groupOrders.forEach((order, index) => {
        this.loadTimerData(order, index);
      });
    },
    loadTimerData(order, index) {
      if (this.groupOrders[index].order_status !== "PENDING") {
        const startTime = this.convertTo24HourFormat(order.approve_timer);
        // const endTime = this.convertTo24HourFormat('02:55:39pm');
        const endTime = this.add30MinutesToTime(
          this.convertTo24HourFormat(order.approve_timer)
        );

        const now = new Date();
        const endDatetime = new Date();
        const startDatetime = new Date();
        endDatetime.setHours(parseInt(endTime.split(":")[0], 10));
        endDatetime.setMinutes(parseInt(endTime.split(":")[1], 10));
        endDatetime.setSeconds(parseInt(endTime.split(":")[2], 10));
        startDatetime.setHours(parseInt(startTime.split(":")[0], 10));
        startDatetime.setMinutes(parseInt(startTime.split(":")[1], 10));
        startDatetime.setSeconds(parseInt(startTime.split(":")[2], 10));

        if (endDatetime.getTime() <= startDatetime.getTime()) {
          // If end time is earlier than start time, assume it's for the next day
          endDatetime.setDate(endDatetime.getDate() + 1);
        }
        this.groupOrders[index].timeLeft = Math.floor(
          (endDatetime.getTime() - now.getTime()) / 1000
        );
        const intervalId = setInterval(() => {
          this.groupOrders[index].timeLeft--;
          if (this.groupOrders[index].timeLeft <= 0) {
            this.stopTimer(index);
          }
        }, 1000);
        this.intervalIds.push(intervalId);
      }
    },
    stopTimer(index) {
      clearInterval(this.intervalIds[index]);
    },
  },
  beforeDestroy() {
    this.intervalIds.forEach((intervalId) => clearInterval(intervalId));
  },
};
</script>

<style scoped>
.card-head-left {
  display: flex;
  color: #fff;
  padding: 0;
}
.resturan-info {
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.resturan-info h6 {
  font-size: 20px;
}
.addonsSections {
  margin-left: 20px;
}
.addonsSections .addon-item {
  display: flex;
  align-items: center;
  margin-top: -2px;
}
.badge-success {
  background-color: green;
  color: #fff;
  /* padding: 5px; */
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 10px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.dateTime {
  font-size: 12px;
  margin-top: 10px;
}
.totalPyaBtn {
  background-color: #d7171c;
  color: #fff;
  width: 100%;
}
.show-price-btn {
  border: 1px solid #d7171c;
  color: #d7171c;
  margin-top: 30px;
  width: 100%;
}
.culculation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}
.culculation .left {
  font-size: 15px;
  font-weight: 500;
}
.take_away_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}
.take_away_btn .button {
  width: 100% !important;
  background-color: #d7171c;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 600;
}
.order-progress-section {
  /* margin: 50px 0; */
  margin-top: 70px;
}

.lg-screen-address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.padding25 {
  padding: 25px;
}
.sm-screen-info {
  display: none;
}
.sm-screen-badge {
  display: none;
}
.card-footer {
  padding: 7px 20px;
  display: flex;
  justify-content: space-between;
}
.card-footer .left {
  color: #fff;
  display: flex;
}
.card-footer .right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 25%;
}
@media (max-width: 1279px) {
  .card-footer .right {
    width: 35% !important;
  }
}
.card-footer .right button {
  border-radius: 25px !important;
  width: 128px;
  margin-right: 5px;
}
.card-footer .left img {
  width: 55px;
  height: 55px;
  border-radius: 100%;
  margin-right: 10px;
}
.dark-btn {
  background-color: #000;
  color: #fff;
}

@media (max-width: 599px) {
  .itemsCard {
    border-radius: 20px !important;
  }
  .v-container {
    padding: 10px !important    ;
  }
  .dateTime {
    margin-top: 0 !important;
    font-size: 8px !important;
  }
  .lg-screen-info {
    display: none !important;
  }
  .card-head-left img {
    height: 70px !important;
    width: 70px !important;
    border-bottom-left-radius: 0 !important;
    /* margin-top: -3px !important; */
  }
  .topbanner {
    border-bottom-right-radius: 0 !important;
  }
  .resturan-info {
    padding-left: 5px !important;
    padding-top: 15px !important;
    padding-bottom: 0 !important;
    width: 70%;
  }

  .resturan-info h6 {
    font-size: 16px !important;
  }
  .resturan-info span {
    font-size: 12px;
  }
  .sm-screen-info {
    display: block !important;
  }
  .badge-success {
    width: auto;
    margin-right: 10px;
  }
  .name-with-phone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;
  }
  .name-with-phone span {
    padding-left: 3px;
  }
  .resturan-info .left,
  .resturan-info .right {
    font-size: 14px !important;
  }
  .sm-screen-badge {
    display: block !important;
    background: green;
    margin-top: 12px;
    color: #fff;
    padding: 0 5px;
    text-align: center;
    font-size: 12px;
  }
  .order-progress-section {
    margin-top: -7px;
  }
  .calculationArea {
    box-shadow: none !important;
  }
  .calculationArea .culculation {
    padding: 2px 10px;
  }
  .scrollerItems {
    padding: 0 20px !important;
  }
  .card-footer {
    padding: 7px;
  }
  .card-footer .left img {
    width: 45px;
    height: 45px;
  }
  .card-footer .left h3 {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }
  .card-footer .left span {
    font-size: 12px;
  }
  .card-footer .right {
    width: 35%;
    display: block;
  }
  .dark-btn {
    margin-top: 5px;
  }
  .card-footer .right button {
    font-size: 10px;
    padding: 0px;
    height: 25px;
    padding: 0 5px;
    width: 100px;
  }
  .culculation .left,
  .culculation .right {
    font-size: 12px;
  }
  .scrollerItems {
    max-height: 140px !important;
  }
  .calculationArea {
    padding: 0 45px;
  }
}
@media (max-width: 360px) {
  .sm-screen-info small {
    font-size: 11px !important;
  }
}
@media (max-width: 551px) {
  .padding25 {
    padding: 10px !important;
  }
}
@media (max-width: 959.99px) {
  .spceing-box {
    height: 30px;
    display: block !important;
  }
}
.down-icon-card {
  cursor: pointer;
}
.calculationArea {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  border-radius: 15px;
}
.tackAwayProgressBar {
}
.padding-10 {
  padding: 01px 0;
}
.timerArea {
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* box-shadow: rgb(0 0 0 / 66%) 0px -50px 36px -28px inset; */
}
.v-alert {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 10px;
}
.vendorInfo {
  display: flex;
  align-items: center;
  background-color: #d7171c;
  box-shadow: rgb(0 0 0 / 66%) 0px -50px 36px -28px inset;
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  /* border-radius: 5px; */
  /* margin-bottom: 15px; */
}
.vendorLogo {
  width: 71px;
  height: 71px;
  border-top-left-radius: 10px;
}

.scrollerItems {
  padding: 20px;
  max-height: 250px;
  overflow: auto;
  margin-top: 10px;
  flex-direction: column-reverse;

  scrollbar-color: #e5e5e5 transparent;

  /* Firefox */
  scrollbar-width: thin;
}

.scrollerItems::-webkit-scrollbar {
  width: 3px; /* Adjust as needed */
}

.scrollerItems::-webkit-scrollbar-thumb {
  background-color: #e5e5e5;
  border-radius: 10px;
}

.scrollerItems::-webkit-scrollbar-track {
  background-color: transparent;
}

.card-head-left img {
  width: 110px;
  height: 113px;
  margin-left: 12px;
  margin-top: 12px;
  /* border-bottom-left-radius:10px ; */
  border-top-left-radius: 10px;
}
.itemsCard {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  border-radius: 30px;
  margin: 30px;
}
.v-container {
  min-height: 600px;
}
.lgScreen-cancelMsg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 26%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 0;
  font-weight: 600;
}
.cancelOrder {
  padding: 5px 10px;
}
/*  */
</style>

<!-- PENDING,APPROVE, -->
