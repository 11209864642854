<template>
    <nav-bar-vue :drawer="drawer" @close-drawer="drawer = !drawer"/>
    <div style="margin-top: 50px;" @click="drawer=false">
        <v-card>
            <v-row>
                <v-col cols="12" sm="12" md="6" class="sm-screen">
                    <div class="video-container">
                        <video  autoplay muted id="vid">
                            <source :src="videoSource" type="video/mp4">
                            <source :src="videoSource" type="video/ogg">
                        </video>
                    </div>
                </v-col>
                <v-col ref="scrollTarget" cols="12" sm="12" md="6">
                    <div class="login-card" >
                        <v-container>
                            <h1>Welcome Back</h1>
                            <span>Sign in to continue</span>
                            <div class="form-dev">
                                <form action="" @submit.prevent="loginSubmit">
                                    <v-text-field
                                       v-model="email"
                                       label="Email"
                                       :rules="emailRules"
                                       variant="outlined"
                                       required
                                       style="margin-bottom: 10px;"
                                     >
                                        <template v-slot:prepend-inner>
                                            <v-icon :color="useVendorStore.resturant.main_color">mdi-email</v-icon>
                                        </template>
                                    </v-text-field>
                                    <v-text-field
                                        v-model="password"
                                        :append-icon-cb="toggleShowPassword"
                                        :rules="passwordRules"
                                        label="Password"
                                        :type="showPassword ? 'text' : 'password'"
                                        variant="outlined"
                                        style="margin-bottom: 15px;"
                                        required
                                    >
                                        <template v-slot:append-inner>
                                            <v-icon @click="toggleShowPassword">{{ showPassword ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                                        </template>
                                        <template v-slot:prepend-inner>
                                            <v-icon :color="useVendorStore.resturant.main_color">
                                            mdi-lock
                                            </v-icon>
                                        </template>
                                    </v-text-field>
                                    <v-btn :style="dynmicBgColor" class="sign-in-btn" :loading="btnloading" type="submit">SIGN IN <v-icon>mdi-login</v-icon></v-btn>
                                    <div class="bottom-links">
                                        <!-- <router-link to="/">Forgot your password?</router-link> -->
                                        <router-link to="/register">Don't have an account? Sign up</router-link>
                                    </div>
                                </form>
                            </div>
    
                        </v-container>
                       
                    </div>
                    
                </v-col>
            </v-row>
            
        </v-card>
        <div class="spceing-box"></div>
        <div class="webFooter"><web-footer/></div>
        <div class="sm-footer">
           <small-screen-footer @open-pos-dilog-box="$router.push('/')" :totalItems="0"/>
       </div>
    </div>
</template>

<script>
import { vendorStore } from '@/store/vendor.js' 
import { useUserStore } from '@/store/user.js' 
import PageLoader from '@/components/PageLoader.vue'
import NavBar from '@/layouts/default/NavBar.vue'
import webFooterComponent from '@/components/webFooterComponent.vue'
import smFooterComponent from '@/components/smFooterComponent.vue' 

 export default{
       components:{  
            'page-loader':PageLoader,
            'nav-bar-vue':NavBar,
            'small-screen-footer':smFooterComponent,
            'web-footer':webFooterComponent,
       },
        setup() {
            const useVendorStore = vendorStore();   
            const userStore = useUserStore();   
            return { useVendorStore,userStore};
        },
       data(){
        return{
            videoSource:'/image/bg.mp4',
            showPassword: false,
            email:null,
            password:null,
            // email:'user@gmail.com',
            // password:'Ahmed33!!',
            btnloading:false,
            emailRules: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',
            ],
            drawer:false,

        }
       },
       mounted() {
            const video = document.getElementById('vid');
            this.scrollToComponent();
            video.addEventListener('ended', () => {
            video.currentTime = 0; 
            video.play(); 
            });
            VendorHelper.updatePageTitle(this.useVendorStore.resturant.name +'- Ozpos');
       },
       computed:{
        dynmicBgColor(){
            return {
                'backgroundColor' :this.useVendorStore.resturant.main_color,
                'color' :this.useVendorStore.resturant.font_color 
            }
        }
       },
       methods:{
        scrollToComponent(){
            if (this.$refs.scrollTarget) {
                this.$refs.scrollTarget.$el.scrollIntoView({
                behavior: 'smooth', // Use 'auto' for instant scroll
                block: 'center',    // Scroll to the vertical center of the component
                inline: 'center',   // Scroll to the horizontal center of the component
             });
            }
        },
            toggleShowPassword() {
              this.showPassword = !this.showPassword;
           },
           loginSubmit(){
            
            this.btnloading=true;
            const obj ={
                email_id:this.email,
                password:this.password,
                provider : "LOCAL",
                vendor_id:this.useVendorStore.vender_id
            }
            this.$axios
                .post(this.useVendorStore.base_url+'user_login_vuejs',obj)
                .then(res =>{
                    this.btnloading=false;
                    if(res.data.success){
                        this.userStore.loginUser(res.data.data,res.data.paymen_setting[0])
                        Notification.loginSuccess();
                        this.$router.push('/');
                    }else{
                         Notification.loginError();
                    }
                }).catch(error =>{
                    this.btnloading=false;
                });
           }
       }
 }
</script>

<style>
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

video {
  width: 100vw;
  height: 100vh;
  object-fit: fill;
}
.form-dev{
    margin-top: 30px;
}
.login-card{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 15%;
    position: relative;
}
.sign-in-btn{
    width: 100%;
    height: 55px !important;
    font-size: 18px;
}
.bottom-links{
    display: grid !important;
    text-align: center;
    margin-top: 20px;
}
.bottom-links a{
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
}
.sm-footer{
   display: none;
}
.webFooter{
    position: absolute;
    bottom: 0;
    right: 0;
}
.spceing-box{
    display: none;
}
@media(max-width:959px) and (min-width: 759px){
    .webFooter{
        bottom: -100px;
    }
}
@media(max-width:768px){
    .webFooter{
        bottom: -147px;
    }
}
@media(max-width:959px){
    .sm-screen{
        display: none !important;
    }
    .login-card{
        margin: 0 7% !important;
    }
    .sm-footer{
      display: block !important;
   }
   /* .spceing-box{
       display: block;
       height: 23px;
   } */
}
</style>
