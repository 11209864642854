<template>
  <div>
    <v-container>
      <v-row v-if="saveAddress.length > 0">
        <v-col cols="12" sm="6" v-for="(address, index) in saveAddress">
          <v-card
            class="mx-auto selectedCard"
            :style="{
              'background-color':
                address.id === oldAddressID || isHoverIndex === index
                  ? useVendorStore.resturant.main_color
                  : '#fff',
              color:
                address.id === oldAddressID || isHoverIndex === index
                  ? useVendorStore.resturant.font_color
                  : '#000',
            }"
            @mouseover="isHoverIndex = index"
            @mouseleave="isHoverIndex = null"
            @click="selectAddress(address)"
          >
            <v-row>
              <v-col cols="12" md="10">
                <v-card-text>
                  <p class="text-h4 text--primary">
                    <v-icon size="20">mdi-home</v-icon>
                    {{ address.type }}
                  </p>
                  <div class="text--primary">
                    <v-icon size="20" co>mdi-map-marker</v-icon>
                    {{ address.address }}
                  </div>
                  <!-- <small>{{ formattedDateTime(address.created_at) }}</small> -->
                </v-card-text>
                <v-card-actions class="addressActions">
                  <v-btn
                    variant="text"
                    color="teal-accent-4"
                    @click.stop="editAdress(index, address)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                    Edit
                  </v-btn>
                  <v-btn
                    variant="text"
                    @click.stop="deleteAdress(index, address)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-col>
              <v-col class="selectbutton" cols="12" md="2">
                <v-btn style="margin-right: 15px">Select</v-btn>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-card
                v-if="reveal === index"
                class="v-card--reveal"
                style="height: 100%"
                :loading="updateLoad"
              >
                <v-card-text class="pb-0" v-if="actionType === 'edit'">
                  <div class="location-check">
                    <input
                      type="text"
                      @click.stop
                      class="locationSeach typeCange mr-2"
                      v-model="addressType"
                      :style="{
                        border:
                          '1px solid ' + useVendorStore.resturant.main_color,
                        'border-radius': '5px',
                      }"
                    />
                    <div class="inner-location-search">
                      <vue-google-autocomplete
                        @click.stop
                        id="autocomplete"
                        :country="vendorLocationsInMap"
                        class="locationSeach"
                        v-model="address_txt"
                        placeholder="Enter your address"
                        v-on:placechanged="getAddressData"
                        :style="{
                          border:
                            '1px solid ' + useVendorStore.resturant.main_color,
                        }"
                      ></vue-google-autocomplete>
                      <v-btn
                        :style="dynmicBgColor"
                        :disabled="!textAddress"
                        @click.stop="updateAddress(address)"
                        :loading="updateLoad"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
                <v-card-title v-if="actionType === 'delete'"
                  >Delete Record</v-card-title
                >
                <v-card-text v-if="actionType === 'delete'">
                  Are you sure you want to delete this record?
                </v-card-text>
                <v-card-actions class="pt-0 addressActions">
                  <v-btn
                    variant="text"
                    color="teal-accent-4"
                    @click.stop="reveal = ''"
                  >
                    Close
                  </v-btn>
                  <v-btn
                    :loading="updateLoad"
                    @click.stop="deleteRecord(index, address)"
                    v-if="actionType === 'delete'"
                    :style="dynmicBgColor"
                    >Yes</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-alert
        v-else
        :value="showMessage"
        type="warning"
        icon="mdi-alert-circle"
        transition="scale-transition"
        outlined
      >
        <template v-slot:icon="{ attrs }">
          <v-icon v-bind="attrs" color="warning">mdi-alert-circle</v-icon>
        </template>
        Save address not found
      </v-alert>
    </v-container>
    <v-dialog v-model="showCityAreas" max-width="500px" persistent>
      <choose-city-areas
        :useVendorStore="useVendorStore"
        :themColorWithFont="dynmicBgColor"
        @close="showCityAreas = false"
        @select-area="handleSelectArea"
        :selectedAddress="selectedTempAddresses"
        :isEditAddress="true"
      />
    </v-dialog>
  </div>
</template>

<script>
import { vendorStore } from "@/store/vendor.js";
import ChooseAreasModal from "@/components/ChooseAreasModal.vue";
import { useUserStore } from "@/store/user.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  props: [
    "saveAddress",
    "oldAddressID",
    "vendorLocationsInMap",
    "invalidTimeErr",
    "invalidTimeAlert",
  ],
  components: {
    VueGoogleAutocomplete,
    "choose-city-areas": ChooseAreasModal,
  },
  setup() {
    const useVendorStore = vendorStore();
    const userStore = useUserStore();
    return { useVendorStore, userStore };
  },
  data() {
    return {
      reveal: "",
      address: "",
      lang: "",
      lat: "",
      textAddress: true,
      addressType: "",
      updateLoad: false,
      address_txt: "",
      actionType: "",
      isHoverIndex: null,
      selectedTempAddresses: {},
      showCityAreas: false,
      editAddressId: null,
    };
  },
  computed: {
    textAddress() {
      return !!this.addressType;
    },
    dynmicBgColor() {
      return {
        backgroundColor: this.useVendorStore.resturant.main_color,
        color: this.useVendorStore.resturant.font_color,
      };
    },
  },
  mounted() {},
  methods: {
    handleSelectArea(data) {
      console.log(data);
      this.addressType = data.addressType;
      this.lang = data.selectedArea.lang;
      this.lat = data.selectedArea.lat;
      this.address_txt = data.fullAddress;
      this.updateAddress({ location_accuracy: data.locationDevice });
    },
    getAddressData: function (addressData, placeResultData, id) {
      this.address = addressData;
      this.lang = this.address.longitude;
      this.lat = this.address.latitude;
      this.address_txt =
        this.address.route +
        "," +
        this.address.locality +
        "," +
        this.address.country;
    },
    updateAddress(address) {
      this.updateLoad = true;
      let obj = address;
      obj.type = this.addressType;
      obj.lang = this.lang;
      obj.lat = this.lat;
      obj.id = this.editAddressId;
      if (this.address_txt) {
        obj.address = this.address_txt;
      }
      console.log("adasd");
      console.log(obj);
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      const base_url = this.useVendorStore.base_url;
      this.$axios
        .post(base_url + "updateUserAddressVuejs", obj, { headers })
        .then((res) => {
          this.showCityAreas = false;
          console.log(res.data);
          this.updateLoad = false;
          Notification.success();
          this.$emit("after-address-success");
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
          if (error.response.status === 401) {
            this.userStore.logout();
            this.$router.push("/");
          }
        });
    },
    editAdress(index, address) {
      this.addressType = address.type;
      this.editAddressId = address.id;
      console.log(address);
      this.lang = address.lang;
      this.actionType = "edit";
      this.lat = address.lat;
      if (this.useVendorStore.resturant.country === "Pakistan") {
        this.showCityAreas = true;
        this.selectedTempAddresses = {
          fullAddress: address.address,
          selectedCity: null,
          selectedArea: null,
          addressType: address.type,
        };
      } else {
        this.reveal = index;
      }
    },
    deleteAdress(index, address) {
      this.reveal = index;
      this.actionType = "delete";
    },
    formattedDateTime(dateTime) {
      // return dateTime.toLocaleDateString("en-US");
      // return dateTime
    },
    selectAddress(address) {
      this.$emit("select-old-address", address);
    },
    deleteRecord(index, data) {
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      const base_url = this.useVendorStore.base_url;
      this.$axios
        .get(base_url + "deleteUserAddressVuejs/" + data.id, { headers })
        .then((res) => {
          this.reveal = "";
          this.showCityAreas = false;
          this.updateLoad = false;
          Notification.success();
          this.$emit("after-address-success");
        })
        .catch();
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.v-card-text {
  padding-bottom: 0 !important;
}
.addressActions {
  position: absolute;
  bottom: 0;
}
.location-check {
  display: flex;
}
.location-check .v-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 45px;
}

.locationSeach {
  width: 100%;
  padding: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.locationSeach:focus {
  border: 1px solid #d7171c;
  width: 100%;
  padding: 6px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.selectedCard {
  min-height: 120px;
}
.selectedCard:hover {
  background-color: #d7171c;
  color: #fff;

  /* cursor: pointer; */
}
.white-color {
  color: #fff;
}
.selectedCard:hover .icon-color {
  color: #fff !important;
}
.selectbutton {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 959.99px) {
  .location-check {
    display: grid !important;
  }
  .inner-location-search,
  .typeCange {
    width: 100% !important;
    margin-bottom: 5px;
  }
  .selectbutton .v-btn {
    position: absolute;
    bottom: 5px;
    right: 0;
  }
  .selectedCard {
    min-height: 160px;
  }
  .text-h4 {
    font-size: 1.5rem !important;
  }
}
.inner-location-search {
  width: 50%;
  display: flex;
}
.typeCange {
  width: 40%;
  /* height: 35px; */
}

</style>
