<template>
  <div class="outer-hero-section">
    <v-main class="hero-banner">
      <v-carousel
        cycle
        hide-delimiter-background
        show-arrows-on-hover
        interval="3000"
        height="480"
        class="lg-screen-slider"
      >
        <v-carousel-item
          v-for="(item, index) in lgScreenSlideImg"
          :key="index"
          :src="item.image"
          height="480"
        >
        </v-carousel-item>
      </v-carousel>
      <v-carousel
        cycle
        hide-delimiter-background
        show-arrows-on-hover
        interval="3000"
        height="480"
        class="sm-screen-slider"
      >
        <v-carousel-item
          v-for="(item, index) in smScreenSlider"
          :key="index"
          :src="item.image"
          height="480"
        >
        </v-carousel-item>
      </v-carousel>
      <div class="slider-overlay">
        <v-container>
          <v-row>
            <v-col cols="12" md="7" sm="12" class="text-container">
              <div class="text-content">
                <div>
                  <h2>{{ resturant.name }}</h2>
                  <!-- <img :src="resturant.image" alt="Image" class="image hero-img vendorSmLogo"/> -->
                </div>
                <div class="vendorName">
                  <v-icon>mdi-map-marker</v-icon>{{ resturant.address }}
                </div>
                <!-- <div class="rating-list">
                           <v-rating color="yellow" v-model="rating" :hover="hoverEnabled" :readonly="readonly" dense half-increments></v-rating>
                           <small>(245 Reviews)</small> 
                       </div> -->
              </div>
            </v-col>
            <v-col cols="12" md="5" sm="12" class="rightBanner">
              <!-- <img :src="resturant.image" alt="Image" class="image hero-img"/> -->
              <span @click="initiateCall" style="cursor: pointer"
                ><v-icon>mdi-phone</v-icon>{{ resturant.contact }}</span
              >
              <div >
                <div  class="hero-time-show mt-2" v-if="todayTiming.status === 1">
                  <div class="in-clock">
                    <v-icon>mdi-clock</v-icon>
                    <span>
                      Open: {{ openTime }}
                    </span>
                  </div>
                  <div>
                    <v-icon>mdi-clock</v-icon>
                    <span> Close: {{ closeTime }}</span>
                  </div>
                </div>
                <div v-else>
                  <v-icon>mdi-cancel</v-icon>
                   <span style="margin-left:10px">Closed</span>
                </div>
                <v-tooltip activator="parent" location="center">
                  <div>
                    <v-col cols="12" v-for="(day,index) in workingDays" :key="index">
                      <span>{{ day.day_index }}</span>
                      <div v-if="day.status === 1">
                      <div  style="font-size: 12px" v-for="(period,ind) in day.period_list" :key="ind"> 
                        <!-- <span v-if="day.status === 1" style="font-size: 12px"
                          >{{ day.period_list[0].start_time }} -
                          {{ day.period_list[0].end_time }}</span
                        > -->
                        <span 
                          >
                          {{period.start_time +" - " + period.end_time}}
                          </span
                        >
                       
                      </div>
                      </div>
                       <span v-else style="font-size: 12px">  Close </span>
                    </v-col>
                  </div>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-main>
    <div class="hero-bottom-bar" :style="themColorWithFont">
      <v-container>
        <div class="inner-bottom-bar">
          <div class="info">
            <p>
              <strong>VENDOR :</strong>
              <span v-if="checkVendor">ONLINE</span>
              <span v-else>OFFLINE</span>
            </p>
            <p>
              <strong>DELIVERY :</strong>
              <span v-if="resturant.delivery_status === 1">ON</span>
              <span v-else>OFF</span>
            </p>
            <p>
              <strong>PICKUP :</strong>
              <span v-if="resturant.pickup_status === 1">ON</span>
              <span v-else>OFF</span>
            </p>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { vendorStore } from "@/store/vendor.js";
import moment from "moment-timezone";
export default {
  props: [
    "resturant",
    "todayTiming",
    "currentDay",
    "currentTime",
    "openTime",
    "closeTime",
    "workingDays",
    "isOpen"
  ],
  setup() {
    const useVendorStore = vendorStore();
    return { useVendorStore };
  },
  data() {
    return { 
      
      rating: 3,
      hoverEnabled: true,
      readonly: false,
      carouselItems: [],
      dynamicColors:"#fff",
      imgARr: [
        { image: "/image/m_slide_04.jpg" },
        { image: "/image/m_slide_05.jpg" },
      ],
    };
  },
  mounted() {
    this.vendorLoad();
  },
  computed: {
    isOpen() {
      return VendorHelper.isOpen(
        this.currentTime,
        // this.openTime,
        // this.closeTime,
        this.todayTiming
      );
    },
    checkVendor() {
      return VendorHelper.isVenderOnOrOff(
        this.todayTiming,
        this.isOpen,
        this.resturant.vendor_status
      );
    },
   
    smScreenSlider() {
      return this.carouselItems.filter((item) => item.platform === "mobile");
    },
    lgScreenSlideImg() {
      return this.carouselItems.filter((item) => item.platform === "web");
    },
    themColorWithFont(){
      return {
        'background-color':this.useVendorStore.resturant.main_color,
        'color':this.useVendorStore.resturant.font_color
      }
    },
    themColor(){
      return {
        'color':this.useVendorStore.resturant.main_color
      }
    }
  },
  methods: {
  isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
    },
    initiateCall(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    toggleText(index, value) {
      this.carouselItems[index].showText = value;
    },
    vendorLoad() {
      this.$axios
        .get(
          this.useVendorStore.base_url +
            "vueSlider/" +
            this.useVendorStore.vender_id
        )
        .then((response) => {
          console.log("sliderss ss");
          console.log(response);
          this.carouselItems = response.data.slider;
        })
        .catch((error) => {});
    },
  },
};
</script>
<style>
.hero-banner {
  color: #fff;
  position: relative;
  background-color: #000;
}
.vendorSmLogo {
  display: none;
}
.slider-overlay {
  /* position: absolute;
    bottom: 0;
    width: 100%;
    background: #000;
    opacity: 0.8;
    min-height: 130px; */
}
.hero-banner .v-icon{
  font-size: 20px;
}
.slider-overlay .text-content {
  text-align: initial;
}
.outer-hero-section {
  position: relative;
  margin-top: -65px;
}
.hero-bottom-bar {
  padding: 0 5%;
  margin: 0 30px;
  /* position: absolute;
  top: 92%;
  left: 30px;
  right: 30px; */
  border-radius: 10px;
  z-index: 9;
}
.inner-bottom-bar .info {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.rating-list {
  display: flex;
  align-items: center;
}
.hero-time-show {
  display: flex;
}
.hero-time-show .in-clock {
  margin-right: 30px;
}
.hero-img {
  width: 25%;
  float: right;
}

/* cusrolse style */

.show-text {
  opacity: 1;
}

.text-container {
  text-align: center;
  color: white;
}

.v-window__left,
.v-window__right {
  --dynamic-color: var(--dynamicColors);
  background-color: var(--dynamic-color)
  /* color: {{ dynamicColors }} !important; */
  /* background-color: #d7171d44 !important;
  color: #fff !important; */
}
.vendorName{
  width:80%;
}
/* end cusrolse style */
@media (max-width: 959.99px) {
  .hero-banner {
    height: 100% !important;
  }
  .image-container {
    display: none !important;
  }
  .vendorSmLogo {
    display: block !important;
  }
  .slider-overlay {
    bottom: 9% !important;
    z-index: 9 !important;
  }
  .hero-time-show .in-clock {
    margin-right: 8px !important;
  }
  .text-container {
    padding-bottom: 0;
  }
  .hero-bottom-bar {
    position: absolute;
    margin: 0 !important;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0 !important;
  }
}

@media (max-width: 425px) {
  .inner-bottom-bar .info {
    font-size: 12px !important;
  }
}
.carousel-image {
  height: 100%;
  background-size: cover;
  background-position: center;
}
.v-img__img {
  object-fit: cover !important;
}
.sm-screen-slider {
  display: none;
}
@media (max-width: 768px) {
  .text-content h2 {
    font-size: 16px;
  }
  .lg-screen-slider {
    display: none !important;
  }
  .sm-screen-slider {
    display: block !important;
  }
  .rightBanner {
    padding-top: 0 !important ;
  }
  .rightBanner span,
  .text-content span {
    font-size: 14px;
  }
  .vendorName{
    width:100% !important
  }
}
</style>
