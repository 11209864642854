<template>
    <v-footer app :style="{
      'backgroundColor':useVendorStore.resturant.main_color,
      'color' : useVendorStore.resturant.font_color
    }">
      <v-row justify="center" align="center">
        <v-col cols="2" class="itemBox">
          <router-link :to="useVendorStore.homePageURL" class="routerLink">
            <v-icon>mdi-home</v-icon>
            <span>Home</span>
          </router-link>
        </v-col>
        <v-col cols="2" class="itemBox" v-if="$route.name != 'qrCode'">
          <router-link to="/contact-us" class="routerLink">
            <v-icon>mdi-phone</v-icon>
            <span>Contact</span>
          </router-link>
        </v-col>
        <v-col cols="2" class="itemBox" v-if="$route.name === 'qrCode'">
          <router-link to="/about-us" class="routerLink">
            <v-icon>mdi-information</v-icon>
            <span>About</span>
          </router-link>
        </v-col>
        <v-col cols="3" class="itemCartBox">
          <v-btn class="cartBtn" :style="{
            'color':useVendorStore.resturant.main_color
          }" @click="$emit('open-pos-dilog-box')">
            <v-icon>mdi-cart</v-icon>
            <v-badge color="green" overlap>
              <template v-slot:badge>
                <span class="badge-number">{{ totalItems }}</span> 
              </template>
            </v-badge>
          </v-btn>
        </v-col>
        <v-col cols="2" class="itemBox" v-if="$route.name != 'qrCode' && !checkLoggin">
          <router-link to="/login" class="routerLink">
            <v-icon>mdi-account-circle</v-icon>
            <span>Login</span>
          </router-link>
        </v-col>
        <v-col cols="2" class="itemBox" v-if="$route.name != 'qrCode' && checkLoggin">
          <router-link to="/orders" class="routerLink">
            <v-icon>mdi-shopping</v-icon>
            <span>Orders</span>
          </router-link>
        </v-col>
        <v-col cols="2" class="itemBox" v-if="$route.name === 'qrCode'"> 
          <router-link to="/contact-us" class="routerLink">
            <v-icon>mdi-phone</v-icon>
            <span>Contact</span>
          </router-link>
        </v-col>
        <v-col cols="2" v-if="$route.name != 'qrCode'" class="itemBox" @click="goToProfile">
          <v-icon>mdi-account</v-icon>
          <span>Profile</span>
        </v-col>
        <v-col cols="2" v-if="$route.name === 'qrCode'" class="itemBox" @click="goToProfile">
          <router-link to="/privacy" class="routerLink">
            <v-icon>mdi-shield-check-outline</v-icon>
            <span>Privacy</span>
          </router-link>
        </v-col>
      </v-row>
    </v-footer>
  </template>

<script>
import { vendorStore } from '@/store/vendor.js'
  export default{
    props:['totalItems'],
    setup() {
        const useVendorStore = vendorStore(); 
         return { useVendorStore};
    },
    data(){
      return{ 
       
      }
    },
    computed:{
      checkLoggin(){
       return User.loggedIn();
      }
    },
    methods:{
      goToProfile(){
        if(this.$route.name != 'qrCode'){
          if(User.loggedIn()){
              this.$router.push('/profile')
          }else{
            this.$router.push('/login')
          }
        }
      }
    }
  }
</script>
<style scoped>
.v-footer{
  position: fixed !important;
  /* background-color: #d7171c;
  color: #fff; */
  height: 45px;
}

.itemBox{
   text-align: center;
   text-align: center;
    font-size: 12px;
    font-weight: 500;
    display: grid;
    cursor: pointer;
    
}
.cartBtn{
  background-color: #fff;
    border-radius: 100%;
    height: 65px;
    font-size: 25px;
    position: absolute;
    bottom: 0px;
}
.itemBox .v-icon{
  width: 100%;
} 
.itemCartBox{
  display: flex;
  justify-content: center;
}
.itemCartBox .v-badge{
  position: absolute;
  top: 20px;
  right:15px
}
.routerLink{
  text-decoration: none;
  color: #fff;
}
</style>