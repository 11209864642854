import { defineStore } from 'pinia'
export const posStore = defineStore('pos', {
  state: () => ({
     items:null,
     items_sizes_addon:[],
     cartData:JSON.parse(localStorage.getItem('cartData')) ||[],
     posItems:JSON.parse(localStorage.getItem('posItems')) ||[],
     free_delivery_amount:JSON.parse(localStorage.getItem('free_delivery_amount'))|| 0 ,
     deliveryChargeData:JSON.parse(localStorage.getItem('deliveryChargeData')) || {},
     deliveryCahrge:JSON.parse(localStorage.getItem('deliveryCahrge'))|| 0,
     delivery_type:JSON.parse(localStorage.getItem('deliveryType')) || 'pickup',
     dateTimeValue:JSON.parse(localStorage.getItem('dateTimeValue')) || '',
     selected_driver:JSON.parse(localStorage.getItem('selected_driver')) || {},
     tempData:{},
     userAddress:{},
    //  deliveryStatusTime:localStorage.getItem('deliveryStatusTime') || 'Now',
    //  isvalidDateTime:localStorage.getItem('isvalidDateTime') || false,
  }),
  actions: {
    async posStorage(items,items_sizes_addon) {
         this.items=items;
         this.items_sizes_addon = items_sizes_addon;  
    },
    async PosItemStore(posdata){
      localStorage.setItem('posItems',JSON.stringify(posdata)); 
    },
    async checkOutStorage(posCartData , totalCulculate){
        const obj = {
          posCartData:posCartData,
          totalCulculate:totalCulculate,
        }
        this.tempData = totalCulculate;
        localStorage.setItem('cartData',JSON.stringify(obj)) || {};
        localStorage.setItem('posItems',JSON.stringify(posCartData));
        localStorage.setItem('free_delivery_amount',JSON.stringify(totalCulculate.free_delivery_amount));
        localStorage.setItem('deliveryChargeData',JSON.stringify(totalCulculate.deliveryChargeData));
        localStorage.setItem('deliveryCahrge',JSON.stringify(totalCulculate.deliveryCahrge));
        localStorage.setItem('deliveryType',JSON.stringify(totalCulculate.delivery_type));
        localStorage.setItem('dateTimeValue',JSON.stringify(totalCulculate.dateTimeValue));
        // localStorage.setItem('isvalidDateTime',totalCulculate.isvalidDateTime);
        // localStorage.setItem('deliveryStatusTime',totalCulculate.delivery_time);
        this.cartData = obj;
        this.free_delivery_amount = totalCulculate.free_delivery_amount;
        this.deliveryChargeData = totalCulculate.deliveryChargeData;
        this.deliveryCahrge = totalCulculate.deliveryCahrge;
    },
   async liveTrackingData(data){
    const obj = JSON.stringify(data)
    localStorage.setItem('selected_driver',obj);
    this.selected_driver = data;
   },
    async pkUserAddress(address){
       this.userAddress = address;
    },
    async fleshOrders(){
          localStorage.removeItem('cartData');
          localStorage.removeItem('posItems');
          localStorage.removeItem('free_delivery_amount');
          localStorage.removeItem('deliveryChargeData');
          localStorage.removeItem('deliveryCahrge');
          localStorage.removeItem('deliveryType');
          localStorage.removeItem('dateTimeValue');
          localStorage.removeItem('isvalidDateTime');
          localStorage.removeItem('deliveryStatusTime');
          localStorage.removeItem('selected_driver');
          this.cartData =[];
          this.posItems = [];
          this.selected_driver = {};
          this.free_delivery_amount=0;  
          this.deliveryChargeData = {};
          this.deliveryCahrge = 0;
          this.items=null;
          this.items_sizes_addon = [];
          this.tempData ={};
          this.dateTimeValue = null;

  
    }
  },
});