/**
 * main.ts
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Components
import App from './App.vue'

// Composables
import { createApp } from 'vue'
import 'vuetify/dist/vuetify.min.css'

// Plugins
import { registerPlugins } from '@/plugins'
import axios from 'axios'
import VueSweetalert2 from 'sweetalert2'
import Noty from 'noty'
import 'noty/lib/noty.css'
import 'noty/lib/themes/mint.css'
import '@/assets/css/style.css'
import { Notification } from '@/helper/Notification'
import  VendorHelper  from '@/helper/helper.js';
import VueStickyDirective from 'vue-sticky-directive'
import  User  from '@/helper/User.js';
import router from '@/router/index';
import {toggleConsoleOutput} from "@/helper/utils.js"
window.User = User;
// window.VendorHelper = VendorHelper;
(window as any).VendorHelper = VendorHelper;
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

toggleConsoleOutput(process.env.NODE_ENV !== 'production');

declare global {
    interface Window { 
        Toast: any;
        Noty: any;
        Notification: any;
        User: any;
    }
  }

window.Noty = Noty;
window.Notification = Notification;



// import AppStorage  from '@/helper/AppStorage.js';
// window.AppStorage =AppStorage;

const app = createApp(App)



registerPlugins(app);
app.config.globalProperties.$swal = VueSweetalert2;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$noty = Noty


window.Toast = VueSweetalert2.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", VueSweetalert2.stopTimer);
        toast.addEventListener("mouseleave", VueSweetalert2.resumeTimer);
    },
});
app.use(pinia);
app.use(VueStickyDirective);
app.mount('#app')
