<template>
    <div class="outerFooterinf">
        <a href="https://ozpos.net.au" style="text-decoration: none; font-size:16px;font-weight:400;color:#000">
        <div class="footerInfo" style="cursor: pointer;" >
          <span>Powered by</span>
          <img class="logo" src="/icons/loggoo.png" alt="" srcset="">
          <v-tooltip
                activator="parent"
                location="start"
            >{{useVendorStore.base_url}}</v-tooltip>
        </div>
    </a>
    </div>
</template>
<script>
import { vendorStore } from "@/store/vendor.js";
export default{
    setup() {
        const useVendorStore = vendorStore();
        return {useVendorStore};
    }, 
    mounted(){
    }
}
</script>

<style scoped>
.footerInfo{
    display: flex;
    align-items: center;
    
}
.logo{
    width: 60px;
}
.outerFooterinf{
    display: flex;
    justify-content:end;
    padding-right: 30px;
    
    
}
@media(max-width:768px){
    .outerFooterinf{
        margin-bottom: 50px;
    }
}
</style>