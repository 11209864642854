<template>
  <v-card>
    <div class="card-header" :style="dynmicBgColor">
      <div style="margin-top: 7px">
        <img class="resturent-img" :src="vendorData.image" alt="" srcset="" />
      </div>
      <div class="resturant-info">
        <h3 class="h3-heading">{{ vendorData.name }}</h3>
        <div class="location">
          <v-icon style="color: #fff">mdi-map-marker</v-icon
          >{{ truncatedString(vendorData.address, 40) }}
        </div>
        <p>Estimate Time :{{ vendorData.avg_delivery_time }} minutes</p>
      </div>
      <v-tooltip activator="parent" location="bottom">
        <div class="resturant-info">
          <h3 class="h3-heading">{{ vendorData.name }}</h3>
          <div class="location">
            <v-icon style="color: #d7171c">mdi-map-marker</v-icon
            >{{ vendorData.address }}
          </div>
        </div>
      </v-tooltip>
    </div>
    <!-- <v-divider></v-divider> -->
    <div class="input-field-cus">
      <div class="scrollable-section">
        <div class="scrollable-content">
          <v-card
            class="item-card"
            v-for="(cartData, index) in posCartData"
            :loading="CardisLoading[index]"
            :key="index"
          >
            <div class="item-card-top">
              <v-row>
                <v-col cols="7" style="display: flex">
                  <div class="dote-icon" :style="dynmicFontColor">
                    <v-icon style="font-size: 7px">mdi-circle</v-icon>
                  </div>
                  <div class="item-namePrice">
                    <h6>{{ cartData.selectdItems.name }}</h6>
                    <small v-if="cartData.selectdItems.price"
                      >{{
                        formattedPrice(
                          calculateQtyPrice(
                            cartData.selectdItems.qty,
                            cartData.selectdItems.price
                          )
                        )
                      }}</small
                    >
                    <div v-for="size in cartData.selectdItems.sizes" :key="size">
                      <small
                        v-if="size.selected"
                        style="margin-right: 4px; font-weight: 500"
                        >({{ size.item_size.name }})</small
                      >
                      <small v-if="size.selected"
                        >{{
                          formattedPrice(
                            calculateQtyPrice(
                              cartData.selectdItems.qty,
                              size.price
                            )
                          )
                        }}</small
                      >
                    </div>
                  </div>
                </v-col>
                <v-col
                  cols="5"
                  style="display: flex; justify-content: space-between"
                >
                  <div class="qty-section">
                    <v-btn
                      class="qty-btn"
                      :style="dynmicFontColor"
                      icon
                      @click="decrement(index)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <input
                      type="text"
                      readonly
                      class="count-box"
                      :style="{
                        border:
                          '1px solid ' + useVendorStore.resturant.main_color,
                      }"
                      v-model="cartData.selectdItems.qty"
                    />
                    <v-btn
                      class="qty-btn"
                      :style="dynmicFontColor"
                      icon
                      @click="increment(index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <v-icon class="item-remove" @click="removeItem(index)"
                    >mdi-delete</v-icon
                  >
                </v-col>
              </v-row>
            </div>
            <div class="item-card-bttom">
              <div
                class="item-extra-things"
                v-for="(addon, ind) in cartData.SelectedSizes.addons"
                :key="ind"
                :style="{
                  'border-left':
                    '2px solid ' + useVendorStore.resturant.main_color,
                }"
              >
                <div class="line" :style="dynmicBgColor"></div>
                <div style="width: 180px">
                  <small>{{ addon.addon.name }}</small>
                  <small style="margin-left: 4px; font-size: 10px">
                    ({{ calculateQtyPrice(addon.qty, addon.price) }})
                  </small>
                </div>
                <div class="qty-section ml-3" v-if="addon.price > 0">
                  <v-btn
                    class="qty-btn"
                    :style="themColor"
                    icon
                    @click="addonDecrement(index, ind, 'isSizeAddon')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <input
                    style="height: 16px; width: 22px; font-size: 13px"
                    type="text"
                    readonly
                    class="count-box"
                    v-model="addon.qty"
                    :style="{
                      border:
                        '1px solid ' + useVendorStore.resturant.main_color,
                    }"
                  />
                  <v-btn
                    class="qty-btn"
                    :style="themColor"
                    icon
                    @click="addonIncrement(index, ind, 'isSizeAddon')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div
              class="item-card-bttom"
              v-if="cartData.selectedItemsAdon.length > 0"
            >
              <div
                class="item-extra-things"
                v-for="(addon, addonIndex) in cartData.selectedItemsAdon"
                :key="addonIndex"
                :style="{
                  'border-left':
                    '2px solid ' + useVendorStore.resturant.main_color,
                }"
              >
                <div class="line" :style="dynmicBgColor"></div>
                <div style="width: 180px">
                  <small>{{ addon.name }}</small>
                  <small style="margin-left: 4px; font-size: 10px"
                    >({{ calculateQtyPrice(addon.qty, addon.price) }})
                  </small>
                </div>
                <div class="qty-section ml-3" v-if="addon.price > 0">
                  <v-btn
                    class="qty-btn"
                    :style="themColor"
                    icon
                    @click="addonDecrement(index, addonIndex, 'isGroupAddon')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <input
                    style="height: 16px; width: 22px; font-size: 13px"
                    type="text"
                    readonly
                    class="count-box"
                    v-model="addon.qty"
                    :style="{
                      border:
                        '1px solid ' + useVendorStore.resturant.main_color,
                    }"
                  />
                  <v-btn
                    class="qty-btn"
                    :style="themColor"
                    icon
                    @click="addonIncrement(index, addonIndex, 'isGroupAddon')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="note-sugge">
      <v-textarea
        v-model="order_note"
        label="Add Note"
        :rows="2"
        :counter="100"
        counter-value-color="teal"
        placeholder="Type something..."
        variant="outlined"
        @input="filterEmojis"
      >
        <template v-slot:prepend-inner>
          <v-icon :style="dynmicFontColor">mdi-message</v-icon>
        </template>
      </v-textarea>
    </div>
    <v-divider></v-divider>
    <div class="payment-area">
      <div style="display: flex;justify-content:space-between">
        <div style="display: grid; padding: 5px 0;">
          <span>Item total : {{ formattedPrice(parseFloat(ItemtotaPrice).toFixed(2)) }}</span>
          <span v-if="this.taxRate > 0">Item Tax : {{ formattedPrice(this.taxRate) }}</span>
        </div>
        <div style="display: grid; padding: 5px 0">
          <span v-if="this.deliveryCharge > 0"
            >Delivery Charge : {{ formattedPrice(this.deliveryCharge) }}</span
          >
          <span v-if="stripeValidtion"
            >Card Surcharge : {{ formattedPrice(this.stripeCharges.toFixed(2)) }}</span
          >
          <span v-if="this.vendorData.online_flat_menu_discount !== null"
            >Discount Price: ${{ formattedPrice(calculateFinalPrice.discountedPrice) }}
            </span
          >
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="check-out-btn">
      <!-- <v-btn :disabled="!isLogin" :style="dynmicBgColor" @click="checkOutProduct">To Pay ${{ this.totalPay }}</v-btn> -->

      <v-btn
        :disabled="save_loading || !isLogin || !this.cardForm.isActive"
        @click="checkOutProduct"
        :style="dynmicBgColor"
      >
        <template v-if="save_loading">
          <span>Loading...</span>
          <v-progress-circular
            indeterminate
            size="24"
            color="white"
            class="ml-2"
          ></v-progress-circular>
        </template>
        <template v-else>
          <span v-if="stripeValidtion">
            To Pay {{
              formattedPrice(
                (parseFloat(this.calculateFinalPrice.finalTotalPrice) + parseFloat(this.stripeCharges)).toFixed(2)
              )
            }}</span
          >
          <span v-else> To Pay {{ formattedPrice(this.calculateFinalPrice.finalTotalPrice) }}</span>
        </template>
      </v-btn>

      <v-tooltip v-if="!isLogin" activator="parent" location="bottom">
        Please login Your Account First
      </v-tooltip>
      <!-- <v-tooltip v-if="!stripeValidtion" activator="parent" location="bottom">
        Stripe Field Required!
      </v-tooltip> -->
    </div>
  </v-card>
</template>

<script>
import { posStore } from "@/store/posStore";
import { vendorStore } from "@/store/vendor.js";
import dateTimePicker from "@/components/DateTimePickerComponent.vue";
import { useUserStore } from "@/store/user.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { loadStripe } from "@stripe/stripe-js";

export default {
  props: [
    "vendorData",
    "cardForm",
    "stripe",
    "cardElement",
    "card",
    "stripePercentage",
    "formattedPrice"
  ],
  components: {
    "date-time-picker": dateTimePicker,
    VueGoogleAutocomplete,
    address: "",
  },
  setup() {
    const usePosStore = posStore();
    const useVendorStore = vendorStore();
    const userStore = useUserStore();
    return { usePosStore, useVendorStore, userStore };
  },

  data() {
    return {
      activeTopButton: "",
      activeBottomButton: "",
      CardisLoading: [],
      incrementAudio: "audio/increment.mp3",
      removeAudio: "audio/remove.mp3",
      posCartData: "",
      ItemtotaPrice: "",
      taxRate: "",
      totalPay: "",
      deliveryCharge: "",
      deliveryChargeData: "",
      free_delivery_amount: "",
      allCategories: [],
      dateTimeValue: null,
      delivery_type: "",
      address: "",
      lang: "",
      lat: "",
      order_note: null,
      save_loading: false,
      isChangePrice:false,
    };
  },
  created() {
    if(this.cardForm === undefined && this.isLogin){
    const url = this.$router.resolve({ name: 'resturant' }).href;
    window.location.href = url;

      // this.$router.push('/');
    }
    if (this.usePosStore.posItems.length > 0) {
      this.afterReload();
    } else {
      // if(this.$router.push('/')){
      //     window.location.reload();
      // }
    }
    
    // alert(this.usePosStore.posItems.length <)
  },
  computed: {
    calculateFinalPrice() {
      const previousDiscountPrice = parseFloat(this.usePosStore.cartData?.totalCulculate?.discountedPrice) || 0;
      if(!this.isChangePrice){
        var initialTotalPrice = parseFloat(this.totalPay) + previousDiscountPrice;
        // var initialTotalPrice = parseFloat(this.totalPay) + previousDiscountPrice;
      }else{
        // alert(this.totalPay)
        var initialTotalPrice = parseFloat(this.totalPay);
      }
      let adjustedPrice;
      if (this.delivery_type === "DELIVERY") {
        adjustedPrice = initialTotalPrice - parseFloat(this.deliveryCharge);
      } else {
        adjustedPrice = initialTotalPrice;
      }
      const discountRate = 1 - (parseFloat(this.useVendorStore.resturant.online_flat_menu_discount) / 100 || 0);
      const discountedPrice = adjustedPrice * discountRate;
      const discountAmount = parseFloat(initialTotalPrice) - parseFloat(discountedPrice);
      let finalPrice;
      if (this.delivery_type === "DELIVERY") {
        finalPrice = discountedPrice + parseFloat(this.deliveryCharge);
        var discountAmountNew = discountAmount - parseFloat(this.deliveryCharge);
      } else {
        finalPrice = discountedPrice;
        var discountAmountNew = discountAmount;
      }
      
      return {
        finalTotalPrice: finalPrice.toFixed(2),
        discountedPrice: discountAmountNew.toFixed(2)
      };
    },
    


    calculationPriceArr(){
     const discount =   (1 -  this.useVendorStore.resturant.online_flat_menu_discount / 100);
     const discountPrice = this.totalPay * discount;

     return discountPrice;
    },
    stripeCharges() {
      const stripe = parseFloat(this.stripePercentage);
      return (this.totalPay * stripe) / 100;
    },
    stripeValidtion() {
      if (User.loggedIn()) {
        if (this.cardForm.isActive === "STRIPE") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    isLogin() {
      if (!User.loggedIn()) {
        return false;
      } else {
        return true;
      }
    },
    checkOutItemtotaPrice() {
      var totalPrice = 0;
      this.posCartData.forEach((cartData) => {
        totalPrice += this.SizeAddonTotalPrice(
          cartData,
          cartData.selectdItems.qty
        );
      });
      return totalPrice - this.taxRate;
    },
    checkOuttotalPay() {
      var totalPrice = 0;
      this.posCartData.forEach((cartData) => {
        totalPrice += this.SizeAddonTotalPrice(
          cartData,
          cartData.selectdItems.qty
        );
      });
      return totalPrice;
    },
    checkOuttaxRate() {
      var totalPrice = 0;
      var qty = 0;
      var count = 0;
      this.posCartData.forEach((cartData) => {
        totalPrice += this.SizeAddonTotalPrice(
          cartData,
          cartData.selectdItems.qty
        );
        qty += cartData.selectdItems.qty;
        count++;
      });
      const tax = this.vendorData.tax / 100;
      const totalTax = (totalPrice - totalPrice / (1 + tax)).toFixed(2);
      return totalTax;
    },
    TotalAMountWithTax() {
      return parseFloat(this.taxRate) + parseFloat(this.ItemtotaPrice);
    },
    dynmicFontColor() {
      return {
        color: this.useVendorStore.resturant.main_color,
      };
    },
    dynmicBgColor() {
      return {
        color: this.useVendorStore.resturant.font_color,
        backgroundColor: this.useVendorStore.resturant.main_color,
      };
    },
    checkIfMobile() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      );
    },
  },
  methods: {
    formatPrice(price) {
      const newprice = parseFloat(price);
      return newprice.toFixed(2);
    },
    filterEmojis() {
      this.order_note = this.order_note.replace(
        /[\uD800-\uDBFF][\uDC00-\uDFFF]/g,
        ""
      );
    },
    afterReload() {
      this.deliveryCharge = this.usePosStore.deliveryCahrge;
      this.deliveryChargeData = this.usePosStore.deliveryChargeData;
      this.free_delivery_amount = this.usePosStore.free_delivery_amount;
      this.posCartData = this.usePosStore.cartData.posCartData;
      this.ItemtotaPrice =
        this.usePosStore.cartData.totalCulculate.ItemtotaPrice || 0;
      this.taxRate = this.usePosStore.cartData.totalCulculate.taxRate;
      this.totalPay =
        this.usePosStore.cartData.totalCulculate.totalPay +
        this.usePosStore.deliveryCahrge;
      this.dateTimeValue =
        this.usePosStore.cartData.totalCulculate.dateTimeValue;
      // this.delivery_type=this.usePosStore.cartData.totalCulculate.delivery_type;
      this.address = this.usePosStore.cartData.totalCulculate.address;
      this.lang = this.usePosStore.cartData.totalCulculate.lang;
      this.lat = this.usePosStore.cartData.totalCulculate.lat;
      this.isChangePrice = this.usePosStore.cartData.totalCulculate.isChangePrice;

      if (
        this.usePosStore.cartData.totalCulculate.delivery_type === "delivery"
      ) {
        this.delivery_type = "DELIVERY";
      } else {
        this.delivery_type = "TAKEAWAY";
      }
    },
    async checkOutProduct() {
      // alert(this.calculateFinalPrice.discountedPrice);
      this.save_loading = true;
      var currentDate = VendorHelper.setTimeZone(this.vendorData.timezone);
      var dateObj = new Date(currentDate[2]);
      var time = currentDate[1];
      const year = dateObj.getFullYear();
      const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObj.getDate().toString().padStart(2, "0");
      var date = `${year}-${month}-${day}`;

      var obj = {
        date: date,
        time: time,
        amount: "",
        tax: this.taxRate,
        sub_total: this.ItemtotaPrice,
        item: JSON.stringify(this.placeOrderObject()),
        vendor_id: this.vendorData.id,
        delivery_type: this.delivery_type,
        payment_type: this.cardForm.isActive,
        delivery_date: null,
        delivery_time: this.dateTimeValue ? this.dateTimeValue : null,
        table_no: null,
        promocode_id: null, 
        order_status: "PENDING",
        notes: this.order_note,
        user_name: null,
        discounts: null,
        payment_status: 0,
        delivery_charge:this.deliveryCharge,
        paymentToken: null,
        type: null,
        card_charges: 0,
        address_id: this.usePosStore.cartData.totalCulculate.oldAddressID,
        tiffin_service:this.vendorData.is_tiffin_service,
        discounts:this.calculateFinalPrice.discountedPrice,
        DiscountPercentPOS:this.useVendorStore.resturant.online_flat_menu_discount
      };
      if(this.delivery_type === 'DELIVERY'){
        obj.lat = this.address.latitude;
        obj.lang = this.address.longitude;
        obj.address = this.address.route;
      }
      if (this.useVendorStore.resturant.country === "Pakistan") {
        obj.location_accuracy = this.address.locationDevice;
      }
      if (this.cardForm.isActive === "STRIPE") {
        try {
          const { token, error } = await this.stripe.createToken(
            this.cardElement
          );
          if (error) {
            this.save_loading = false;
            console.log(error);
            Notification.customeTextError(error.message);
          } else {
            console.log("Stripe token:", token.id);
            obj.paymentToken = token.id;
            obj.amount =  parseFloat(this.calculateFinalPrice.finalTotalPrice) + parseFloat(this.stripeCharges);
            // obj.amount =
            //   parseFloat(this.totalPay) + parseFloat(this.stripeCharges);
            obj.type = null;
            obj.card_charges = this.stripeCharges;
            this.postPlaceOrderApi(obj);
          }
        } catch (error) {
          this.save_loading = false;
          console.error("Error creating Stripe token:", error);
        }
      } else {
        obj.type = "home";
        obj.amount  = this.calculateFinalPrice.finalTotalPrice;
        // obj.amount = this.totalPay - parseFloat(this.calculateFinalPrice.discountedPrice);
        obj.card_charges = null;
        // this.save_loading=true;
        this.postPlaceOrderApi(obj);
      }
      // console.log("order object" ,obj)
    },
    postPlaceOrderApi(obj) {
      const headers = {
        Authorization: User.BearerToken(),
        Accept: "application/json",
      };
      this.$axios
        .post(this.useVendorStore.base_url + "apiBookOrderVuejsNew", obj, {
          headers,
        })
        .then((res) => {
          console.log(res.status);
          this.save_loading = false;
          if (res.status === 200) {
            Notification.successPlaceOrder(res.data.message);
            this.usePosStore.fleshOrders();
            this.$router.push("/orders");
          } else {
            Notification.customeTextError(res.data.message);
          }
        })
        .catch((error) => {
          this.save_loading = false;
          this.btnloading = false;
          const { status, data } = error.response;
          if (status === 403) {
              const errors = data.message;
              const errorFields = [
                  'payment_type',
                  'vendor_id',
                  'time',
                  'date',
                  'amount',
                  'sub_total',
                  'item',
                  'delivery_type'
              ];
              errorFields.forEach((field) => {
                  if (errors[field]) {
                      Notification.customeTextError(errors[field][0]);
                  }
              });
          } else {
              Notification.customeTextError(data.message);
          }

          if (status === 401) {
              this.userStore.logout();
              this.$router.push("/login");
          }
      });

    },
    placeOrderObject() {
      var object = {
        vendor_id: this.vendorData.id,
        id: null,
        cart: [],
        editNo: 1,
      };
      this.posCartData.forEach((item) => {
        console.log(item);
        var totalAmount = 0;
        var total_price = 0;
        console.log(item.selectdItems.price);
        if (item.selectdItems.price) {
          totalAmount +=
            parseFloat(item.selectdItems.price) * item.selectdItems.qty;
          total_price +=
            parseFloat(item.selectdItems.price) * item.selectdItems.qty;
        }
        const obj = {
          category: "SINGLE",
          total_amount: 0,
          dining_amount: null,
          menu: [
            {
              id: item.selectdItems.id,
              unique_item_id:VendorHelper.generateUniqueId(), 
              name: item.selectdItems.name,
              image: item.selectdItems.img,
              total_amount: 0,
              addons: [],
              modifiers: [],
              deals_items: null,
            },
          ],
          size: null,
          menu_category: {
            id: item.selectdItems.cat_id,
            name: item.selectdItems.cat_name,
            // image: item.selectdItems.img,
          },
          quantity: item.selectdItems.qty,
        };
        item.selectdItems.sizes.forEach((groupSizes) => {
          if (groupSizes.selected) {
            totalAmount += parseFloat(groupSizes.price);
            total_price += parseFloat(groupSizes.price) * item.selectdItems.qty;
            obj.size = {
              id: groupSizes.item_size.id,
              size_name: groupSizes.item_size.name,
            };
            
            groupSizes.menu_addon.forEach((addon) => {
              if (addon.addon.is_checked) {
                
                const searchAddon = this.getSizesAddonData(
                  item,
                  addon,
                  "isSizesAddons"
                );
                totalAmount += parseFloat(searchAddon.price) * searchAddon.qty;
                obj.menu[0].addons.push({
                  id: addon.addon.id,
                  name: addon.addon.name,
                  price: parseFloat(searchAddon.price) * searchAddon.qty,
                  quantity: searchAddon.qty,
                  dining_price: addon.addon_dining_price,
                });
              }
            });
          }
        });
        console.log(item)
        item.selectdItems.addon.forEach((group) => {
          console.log(group.addon);
          group.addon.forEach((addon) => {
            // console.log(item.selectdItems);
            
              console.log(addon.addon);
              const searchAddon = this.getSizesAddonData(
                item,
                addon,
                "isGroupAddon"
              );
        // if (addon.addon.is_checked) {
            if(searchAddon !== null){
              console.log(searchAddon)
                totalAmount += parseFloat(searchAddon.price) * searchAddon.qty;
                  obj.menu[0].addons.push({
                    id: addon.addon.id,
                    name: addon.addon.name,
                    price: parseFloat(searchAddon.price) * searchAddon.qty,
                    quantity: searchAddon.qty,
                    dining_price: addon.addon_dining_price,
                  });
            }

            
            // }
            // console.log(addon);
          });
          // console.log('TOTA AMOUNT:-'+totalAmount)
        });
        // obj.total_amount = total_price - parseFloat(this.calculateFinalPrice.discountedPrice);
        obj.total_amount = totalAmount;
        // obj.menu[0].total_amount = total_price - parseFloat(this.calculateFinalPrice.discountedPrice);
        obj.menu[0].total_amount = totalAmount;
        obj.menu[0].dining_amount = null;
        object.cart.push(obj);
      });
       console.log('product object')
       console.log(object)
      return object;
    },
    getSizesAddonData(items, addon, status) {
      var addon_data = null;
      if (status === "isSizesAddons") {
        items.selectedItemssizes.forEach((group) => {
          group.addon.forEach((item) => {
            if (item.addon.is_checked) {
              if (addon.addon_id == item.addon_id) {
                addon_data = addon;
              }
            }
          });
        });
      } else {
        console.log(items);
        items.selectedItemsAdon.forEach((item) => {
          if (item.id == addon.addon_id) {
            addon_data = item;
          }
        });
      }

      return addon_data;
    },
    truncatedString(srting, leng) {
      if (srting) {
        if (srting.length > leng) {
          return srting.slice(0, leng) + "...";
        } else {
          return srting;
        }
      }
    },
    deliveryCondition() {
      this.isChangePrice = true;
      this.taxRate = this.checkOuttaxRate;
      this.ItemtotaPrice = this.checkOutItemtotaPrice;

      if (this.free_delivery_amount <= this.TotalAMountWithTax) {
        this.deliveryCharge = 0;
      } else {
        this.deliveryCharge = this.deliveryChargeData.charge;
      }
      this.totalPay =
        (parseFloat(this.checkOuttotalPay) + parseFloat(this.deliveryCharge));
      const obj = {
        taxRate: this.checkOuttaxRate,
        ItemtotaPrice: this.checkOutItemtotaPrice,
        totalPay: this.checkOuttotalPay,
        deliveryCahrge: this.deliveryCharge,
        deliveryChargeData: this.deliveryChargeData,
        free_delivery_amount: this.free_delivery_amount,
        delivery_type: this.delivery_type,
        dateTimeValue: this.dateTimeValue,
        discountedPrice:this.calculateFinalPrice.discountedPrice,
        isChangePrice:this.isChangePrice,
      };
      this.usePosStore.checkOutStorage(this.posCartData, obj);
     
    },
    addonDecrement(itemIndex, addonIndex, status) {
      if (status === "isGroupAddon") {
        if (this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty > 1) {
          this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty--;
        }
      } else {
        if (
          this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty > 1
        ) {
          this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty--;
        }
      }
      this.deliveryCondition();
      // const audio = new Audio(this.incrementAudio);
      // audio.play();
    },
    addonIncrement(itemIndex, addonIndex, status) {
      if (status === "isGroupAddon") {
        this.posCartData[itemIndex].selectedItemsAdon[addonIndex].qty++;
      } else {
        this.posCartData[itemIndex].SelectedSizes.addons[addonIndex].qty++;
      }
      this.deliveryCondition();
      // const audio = new Audio(this.incrementAudio);
      // audio.play();
    },
    increment(index) {
      if (this.posCartData[index].selectdItems.qty++) {
        this.deliveryCondition();
        // const audio = new Audio(this.incrementAudio);
        // audio.play();
      }
    },
    decrement(index) {
      if (this.posCartData[index].selectdItems.qty > 1) {
        this.posCartData[index].selectdItems.qty--;
        this.deliveryCondition();
        // const audio = new Audio(this.incrementAudio);
        // audio.play();
      }
    },
    removeItem(index) {
      this.posCartData.splice(index, 1);
      this.deliveryCondition();
      if (this.posCartData.length < 1) {
        this.$router.push("/");
      }
    },
    calculateQtyPrice(qty, price) {
      return (qty * price).toFixed(2);
    },
    SizeAddonTotalPrice(posData, qty) {
      var totalPrice = 0;
      if (posData.selectdItems.price) {
        totalPrice += parseFloat(posData.selectdItems.price);
      }
      if (Object.keys(posData.SelectedSizes).length > 0) {
        posData.selectdItems.sizes.forEach((size) => {
          if (size.selected) {
            totalPrice += parseFloat(size.price) * qty;
            posData.SelectedSizes.addons.forEach((item) => {
              if (item.addon.is_checked) {
                totalPrice += parseFloat(item.price) * item.qty;
              }
            });
          }
        });
      }
      if (posData.selectedItemsAdon.length > 0) {
        posData.selectedItemsAdon.forEach((item) => {
          totalPrice += parseFloat(item.price) * item.qty;
        });
      }
      const tax = this.taxRate;
      return totalPrice * qty;
    },
  },
};
</script>

<style scoped>
.resturent-img {
  width: 72px;
  height: 72px;
  border-top-left-radius: 15px;
}
.resturant-info {
  padding-left: 10px;
}
.resturant-info h3 {
  font-size: 14px;
}
.resturant-info .location,
.resturant-info p {
  font-size: 10px;
  width: 100%;
}
.v-card {
  border-radius: 15px;
}
.card-header {
  display: flex;
  align-items: center;
  padding: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 72px;
}
.card-body {
  padding: 30px 20px;
}
.input-field-cus {
  padding-top: 15px;
}
.card-body .top-btton-list {
  display: flex;
  justify-content: center;
  align-items: center;
}
.card-body .top-btton-list button {
  padding: 5px 10px;
  color: #5a616a;
  width: 50%;
  border: 1px solid #5a616a;
}
.card-body .top-btton-list button:hover {
  background-color: #5a616a;
  color: #fff;
}
.card-body .top-btton-list .btn1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}
.card-body .top-btton-list .btn2 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.btn-active {
  background-color: #5a616a;
  color: #fff !important;
}
.bottom-list {
  margin-top: 20px;
}
.h3-heading {
  font-weight: 500;
  font-size: 20px;
}
.pickup-addres-card {
  margin-top: 20px;
}
.pickup-addres-card .resturant-info {
  border: 1px solid #d7171c;
  padding: 15px;
  border-radius: 5px;
  position: relative;
}
.badge-text {
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}
.v-badge {
  position: absolute;
  top: 11px;
  right: 60px;
  z-index: -1;
}

.custom-text-field {
  color: #d7171c !important; /* Replace with your desired custom color */
}
.scrollable-section {
  max-height: 300px; /* Set the desired height for the scrollable section */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
}

.scrollable-content {
  width: 100%; /* Allow the content to expand horizontally */
}

.scrollable-content > * {
  margin-right: 8px; /* Add spacing between the items */
}
.scrollable-content .item-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  margin: 5px 0;
  widows: 100%;
  padding: 20px 10px;
  position: relative;
}
.scrollable-content .item-card .dote-icon {
  width: 15px;
  border: 1px solid;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.scrollable-content .item-namePrice {
  padding: 0 10px;
  display: grid;
}
.item-card-top {
  display: flex;
  align-items: center;
}
.qty-section .qty-btn {
  width: 15px;
  height: 15px;
  font-size: 10px;
}
.qty-section .count-box {
  width: 50px;
  width: 33px;
  margin: 0 5px;
  height: 20px;
  border-radius: 5px;
  text-align: center;
}
.item-remove {
  font-size: 20px;
  cursor: pointer;
  color: #d7171c;
}

.scrollable-content .item-namePrice small {
  font-size: 11px;
}
.item-extra-things {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
}
.item-extra-things .line {
  width: 15px;
  height: 2px;
  margin-right: 3px;
}
.check-out-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.check-out-btn .v-btn {
  width: 100%;
  height: 54px;
}
.locationSeach {
  border: 1px solid #d7171c;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
}
.payment-area {
  padding: 0 20px;
  font-size: 14px;
}
.note-sugge {
  padding: 5px 20px;
}

.v-card {
  border-radius: 15px;
}
@media (max-width: 959.99px) {
  .card-header {
    justify-content: start !important;
  }
}
</style>
